import React, { useContext} from 'react';
import { ThemeContext } from '../../App';
import "./MatterDashboard.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const MatterDashboard = () => {
    const theme = useContext(ThemeContext);
    const navigate = useNavigate();
    const labels = [
        {label: 'Status'},
        {label: 'Case Style'},
        {label: 'Client'},
        {label: 'Matter #'},
        {label: 'First Chair'},
        {label: 'Second Chair'},
        {label: 'Third Chair'},
        {label: 'Phase'},
        {label: 'Open Date'}
    ]
    const values = [
        {matterName: 'Rita Arredondo v. Hobby Lobby Stores, Inc., Pasadena Partners, L.P., and Balogh Family Holdings, LTD', client: 'Aspen Insurance', matterNumber: '142.0269', claimNumber: 'CR2070113203', firstChair: 'Erich Schenk', secondChair: 'Jaclyn Graeber', thirdChair: 'Karen Moseley', phase: '3.1', openDate: '10/27/2022', status: 'active'},
        {matterName: 'Rita Arredondo v. Hobby Lobby Stores, Inc., Pasadena Partners, L.P., and Balogh Family Holdings, LTD', client: 'Aspen Insurance', matterNumber: '142.0269', claimNumber: 'CR2070113203', firstChair: 'Erich Schenk', secondChair: 'Jaclyn Graeber', thirdChair: 'Karen Moseley', phase: '3.1', openDate: '10/27/2022', status: 'overdue'},
        {matterName: 'Rita Arredondo v. Hobby Lobby Stores, Inc., Pasadena Partners, L.P., and Balogh Family Holdings, LTD', client: 'Aspen Insurance', matterNumber: '142.0269', claimNumber: 'CR2070113203', firstChair: 'Erich Schenk', secondChair: 'Jaclyn Graeber', thirdChair: 'Karen Moseley', phase: '3.1', openDate: '10/27/2022', status: 'active'},
        {matterName: 'Rita Arredondo v. Hobby Lobby Stores, Inc., Pasadena Partners, L.P., and Balogh Family Holdings, LTD', client: 'Aspen Insurance', matterNumber: '142.0269', claimNumber: 'CR2070113203', firstChair: 'Erich Schenk', secondChair: 'Jaclyn Graeber', thirdChair: 'Karen Moseley', phase: '3.1', openDate: '10/27/2022', status: 'overdue'},
        {matterName: 'Rita Arredondo v. Hobby Lobby Stores, Inc., Pasadena Partners, L.P., and Balogh Family Holdings, LTD', client: 'Aspen Insurance', matterNumber: '142.0269', claimNumber: 'CR2070113203', firstChair: 'Erich Schenk', secondChair: 'Jaclyn Graeber', thirdChair: 'Karen Moseley', phase: '3.1', openDate: '10/27/2022', status: 'active'},
        {matterName: 'Rita Arredondo v. Hobby Lobby Stores, Inc., Pasadena Partners, L.P., and Balogh Family Holdings, LTD', client: 'Aspen Insurance', matterNumber: '142.0269', claimNumber: 'CR2070113203', firstChair: 'Erich Schenk', secondChair: 'Jaclyn Graeber', thirdChair: 'Karen Moseley', phase: '3.1', openDate: '10/27/2022', status: 'active'},
        {matterName: 'Rita Arredondo v. Hobby Lobby Stores, Inc., Pasadena Partners, L.P., and Balogh Family Holdings, LTD', client: 'Aspen Insurance', matterNumber: '142.0269', claimNumber: 'CR2070113203', firstChair: 'Erich Schenk', secondChair: 'Jaclyn Graeber', thirdChair: 'Karen Moseley', phase: '3.1', openDate: '10/27/2022', status: 'active'},
        {matterName: 'Rita Arredondo v. Hobby Lobby Stores, Inc., Pasadena Partners, L.P., and Balogh Family Holdings, LTD', client: 'Aspen Insurance', matterNumber: '142.0269', claimNumber: 'CR2070113203', firstChair: 'Erich Schenk', secondChair: 'Jaclyn Graeber', thirdChair: 'Karen Moseley', phase: '3.1', openDate: '10/27/2022', status: 'active'},
        {matterName: 'Rita Arredondo v. Hobby Lobby Stores, Inc., Pasadena Partners, L.P., and Balogh Family Holdings, LTD', client: 'Aspen Insurance', matterNumber: '142.0269', claimNumber: 'CR2070113203', firstChair: 'Erich Schenk', secondChair: 'Jaclyn Graeber', thirdChair: 'Karen Moseley', phase: '3.1', openDate: '10/27/2022', status: 'overdue'},
        {matterName: 'Rita Arredondo v. Hobby Lobby Stores, Inc., Pasadena Partners, L.P., and Balogh Family Holdings, LTD', client: 'Aspen Insurance', matterNumber: '142.0269', claimNumber: 'CR2070113203', firstChair: 'Erich Schenk', secondChair: 'Jaclyn Graeber', thirdChair: 'Karen Moseley', phase: '3.1', openDate: '10/27/2022', status: 'active'},
        {matterName: 'Rita Arredondo v. Hobby Lobby Stores, Inc., Pasadena Partners, L.P., and Balogh Family Holdings, LTD', client: 'Aspen Insurance', matterNumber: '142.0269', claimNumber: 'CR2070113203', firstChair: 'Erich Schenk', secondChair: 'Jaclyn Graeber', thirdChair: 'Karen Moseley', phase: '3.1', openDate: '10/27/2022', status: 'active'},
        {matterName: 'Rita Arredondo v. Hobby Lobby Stores, Inc., Pasadena Partners, L.P., and Balogh Family Holdings, LTD', client: 'Aspen Insurance', matterNumber: '142.0269', claimNumber: 'CR2070113203', firstChair: 'Erich Schenk', secondChair: 'Jaclyn Graeber', thirdChair: 'Karen Moseley', phase: '3.1', openDate: '10/27/2022', status: 'active'},

    ]

    function truncateString(str, num) {
        if (str.length <= num) {
          return str
        }
        return str.slice(0, num) + '...'
    }

    const goToDetails = function (){
        navigate('/matter-detail');
    }

    // Function to map labels to values
function mapLabelsToValues(labels, valueObj) {
    const labelValueMap = {};
    labels.forEach(labelObj => {
        const label = labelObj.label;
        let value;
        switch(label) {
            case 'Status': value = valueObj.status; break;
            case 'Case Style': value = valueObj.matterName; break;
            case 'Client': value = valueObj.client; break;
            case 'Matter #': value = valueObj.matterNumber; break;
            case 'First Chair': value = valueObj.firstChair; break;
            case 'Second Chair': value = valueObj.secondChair; break;
            case 'Third Chair': value = valueObj.thirdChair; break;
            case 'Phase': value = valueObj.phase; break;
            case 'Open Date': value = valueObj.openDate; break;
            default: value = '';
        }
        labelValueMap[label] = value;
    });
    return labelValueMap;
}
// Mapping each value object to labels
const mappedArray = values.map(valueObj => mapLabelsToValues(labels, valueObj));

console.log("mappe array", mappedArray);

    return (
        <div className="legal" style={{backgroundImage: `url (${theme[0].page_bagkround_image})`}}>
            <div className="logContainer">
            <h1 className="pageHeader" sx={{ display: { lg: 'none' } }}>Case Log</h1>
            <div className="headerItems">
                    <div className="filterBy">
                        <label for="filter">Filter by </label>
                        <select id="filter" className="filter" style={{textAlign: 'center'}}>
                            <option value="all">All</option>
                            <option value="client">Client</option>
                            <option value="status">Status</option>
                            <option value="matterName">Case Style</option>
                            <option value="matterNo">Matter #</option>
                            <option value="openDate">Open Date</option>
                        </select>
                    </div>
                </div>
            {mappedArray.map((item, index) => (
                <Card sx={{ minWidth: 275, marginBottom: '12px', display: { lg: 'none' } }}>
                    <CardContent>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        <span className="label">Status:</span> <FontAwesomeIcon icon="fa-solid fa-circle" style={{color: `${item.status == 'active' ? '#0CBD3E' : '#F43A11'}`}} /> 
                        </Typography>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        <span className="label">Case Style:</span> {item['Case Style']} 
                        </Typography>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        <span className="label">Client:</span> {item['Client']} 
                        </Typography>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        <span className="label">Matter #:</span> {item['Matter #']} 
                        </Typography>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        <span className="label">First Chair:</span> {item['First Chair']} 
                        </Typography>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        <span className="label">Second Chair:</span> {item['Second Chair']} 
                        </Typography>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        <span className="label">Third Chair:</span> {item['Third Chair']} 
                        </Typography>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        <span className="label">Phase:</span> {item['Phase']} 
                        </Typography>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        <span className="label">Open Date:</span> {item['Open Date']} 
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button variant="contained" onClick={goToDetails} size="small">View Case Details</Button>
                    </CardActions>
                </Card>
            ))}
                
                <div className="tableHeaders">
                    {labels.map((item, index) => (
                        <span className="tableHeaderLabel">{item.label}</span>
                    ))}
                </div>
                <div className="tableRowContainer">
                    {values.map((item, index) => (
                        <>
                            <div className="tableRow" onClick={goToDetails}>
                                <div className="tableCell"><FontAwesomeIcon icon="fa-solid fa-circle" style={{color: `${item.status == 'active' ? '#0CBD3E' : '#F43A11'}`}} /></div>
                                <div className="tableCell" title={item.matterName}>{truncateString(item.matterName, 15)}</div>
                                <div className="tableCell" title={item.client}>{truncateString(item.client, 10)}</div>
                                <div className="tableCell">{item.matterNumber}</div>
                                <div className="tableCell">{item.firstChair}</div>
                                <div className="tableCell">{item.secondChair}</div>
                                <div className="tableCell">{item.thirdChair}</div>
                                <div className="tableCell">{item.phase}</div>
                                <div className="tableCell">{item.openDate}</div>
                            </div>
                        </>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default MatterDashboard;