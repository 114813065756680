import React, { useState, useCallback, useEffect } from 'react';
import S3 from 'react-aws-s3';
import { S3 as S3List} from 'aws-sdk';
import { useDropzone } from 'react-dropzone';
import { Buffer } from 'buffer';
import DragNDrop from '../DragNDrop';
import useChannels from '../../../hooks/useChannels';
import usePrograms from '../../../hooks/usePrograms';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
window.Buffer = Buffer;

export default function UploadForm() {
  const clientId = localStorage.getItem("clientID");
  const [files, setFiles] = useState([]);
  const apiEndpointPrograms = `${process.env.REACT_APP_PROGRAM_LIST_ENDPOINT}?network=${clientId}&channelId=5d552216-43f9-4103-b5d8-04a252b3146c`;
  const { programs, loading, error } = usePrograms(apiEndpointPrograms);
  const [selectedProgram, setSelectedProgram] = useState('');
  const [mediaType, setMediaType] = useState('');
  console.log("programs on upload form", programs);

  const handleChannelChange = (event) => {
    setSelectedProgram(event.target.value);
    console.log("Program ID", event.target.value);
  };

  const handleFileTypeChange = (event) => {
    setMediaType(event.target.value);
    console.log("media type", event.target.value);
  }

  const config = {
    bucketName: process.env.REACT_APP_BUCKET_NAME,
    dirName: process.env.REACT_APP_DIR_NAME /* optional */,
    region: process.env.REACT_APP_REGION,
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
    s3Url: process.env.REACT_APP_S3URL
  };

  const s3 = new S3List({
    region: process.env.REACT_APP_REGION,
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
  });
  
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    const params = {
      Bucket: process.env.REACT_APP_BUCKET_NAME,
      Prefix: process.env.REACT_APP_DIR_NAME
    };
    s3.listObjectsV2(params, (err, data) => {
      if (err) {
        console.log("Error", err);
      } else {
        console.log("files to upload", data.Contents);
        setFileList(data.Contents);
      }
    });
  }, []);

  const onDrop = useCallback((acceptedFiles) => {
    setFiles(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleUpload = async () => {
    const uploadPromises = files.map(file => {
      let newFileName = file.name.replace(/\..+$/, "");
      const ReactS3Client = new S3(config);
      return ReactS3Client.uploadFile(file, newFileName).then((data) => {
        if (data.status === 204) {
          console.log("success", data.location);
          return true;
        } else {
          console.log("fail");
          return false;
        }
      });
    });

    const results = await Promise.all(uploadPromises);
    if(results.every(result => result === true)) {
      setFiles([]);
    }
  };

  const dropzoneStyle = {
    height: '200px',
    width: '100%',
    borderWidth: '2px',
    borderColor: '#ccc',
    borderStyle: 'dashed',
    borderRadius: '7px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  };

  return (
    <div className='upload-steps'>
      <h4>File Upload</h4>
      <div style={{display: 'flex', gap: '12px'}}>
        <FormControl>
          <InputLabel id="channel-select-label" style={{color: '#fff', marginTop: 6}}>Select a Program</InputLabel>
          <Select
            labelId="program-select-label"
            id="program-select"
            value={selectedProgram}
            onChange={handleChannelChange}
            style={{backgroundColor: '#ccc', margin: '12px 0', minWidth: 200}}
          >
              <MenuItem key="none-1" value=''>
                &nbsp;
              </MenuItem>
            {programs.map((program) => (
              <MenuItem key={program.id} value={program.id}>
                {program.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl>
          <InputLabel id="mediat-type-select-label" style={{color: '#fff', marginTop: 6}}>Select a Media Type</InputLabel>
          <Select
            labelId="media-type-select-label"
            id="file-type-select"
            value={mediaType}
            onChange={handleFileTypeChange}
            style={{backgroundColor: '#ccc', margin: '12px 0', minWidth: 200}}
          >
            <MenuItem key="none-2" value=''>
                &nbsp;
            </MenuItem>
            <MenuItem key="item-url" value="url">
              Program URL
            </MenuItem>
            <MenuItem key="item-image" value="image">
              Program Image
            </MenuItem>
            <MenuItem key="item-thumbnail-image" value="thumbnailImage">
              Thumbnail Image
            </MenuItem>
          </Select>

        </FormControl>
      </div>
      <div {...getRootProps()} style={dropzoneStyle}>
        <input {...getInputProps()} />
        {
          isDragActive ?
            <p>Drop the files here ...</p> :
            <p>Drag 'n' drop some files here, or click to select files</p>
        }
      </div>
      {files.length > 0 &&
        <div>
          <h4>Files to be uploaded:</h4>
          <ul>
            {files.map((file, index) => <li key={index}>{file.name}</li>)}
          </ul>
        </div>
      }
      <button type="button" onClick={handleUpload} disabled={files.length === 0} style={{marginTop: '12px'}}>Upload</button>
      <h4>Existing Files</h4>
      <ul>
      {fileList.map((file, index) => (
        <li key={index}>
          https://{process.env.REACT_APP_BUCKET_NAME}.s3.{process.env.REACT_APP_REGION}.amazonaws.com/{file.Key}
        </li>
      ))}
    </ul>
    <DragNDrop />
    </div>
  );
}
