import React, { useContext, useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { AuthContextAWS } from '../../contexts/AuthContextAWS';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, useNavigate } from 'react-router-dom';
import { SitePropertiesContext, ThemeContext } from '../App';

import './drawer.scss';
import { Typography } from '@mui/material';

const TemporaryDrawer = (props)=> {
  const navigate = useNavigate();
  const theme = React.useContext(ThemeContext);
  const siteProperties = React.useContext(SitePropertiesContext);
  const [sideMenu, setSideMenu] = React.useState(props.sideMenu);
  const baseUrl = localStorage.getItem("baseUrl");
  const [avatar, setAvatar] = useState("https://cpibonding.app/images/avatar.png");

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const { isAuthenticated, signOut, attributes } = useContext(AuthContextAWS);
  const handleLogout = () => {
    signOut(); // Call the signOut function from the context
    // Additional logout logic specific to this component, if any
  };

  const goToPinnedPage = () => {
    navigate('/matter-detail');
  }

  const icon = "Delete";

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const anchor = "left";

  const list = (anchor) => (
    <>
        <Box
        key="menu-list"
        sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
        role="presentation"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
        style={{backgroundColor: theme[0].side_menu_background_color, height: '100vh'}}
        >
            <List key="list-key" style={{padding: '12px'}}>
              <ListItem key="app-title" disablePadding className="sideMenuItems sidebarTitle" style={{padding: '12px 0', color: theme[0].side_menu_font_color}}>
                <ListItemText key="app-title-text" className="listItemText" style={{textAlign: 'center'}}>
                  {theme[0].side_menu_logo ? <img src={baseUrl+theme[0].side_menu_logo} style={{width: '50%', margin: '0 auto'}}/> : 
                  <span style={{fontWeight: 'bold'}}>{siteProperties && siteProperties[0].sidebar_title}</span>}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                <Avatar alt="Remy Sharp" src={avatar} />
                </ListItemAvatar>
                <ListItemText
                  primary="Jared Holt"
                  secondary={false ? 'Secondary text' : null}
                />
              </ListItem>
              <Divider key="divider1" style={{borderColor: `${theme[0].side_menu_font_color}`, borderWidth: '1px'}} />
              {sideMenu.map((menu, index) => (
                <React.Fragment key={menu.id + "list-container"}>
                  <ListItem key={menu.id} disablePadding className="sideMenuItems" style={{padding: '12px 0'}}>
                      <ListItemText key={menu.id + "text"} className="listItemText">
                          <FontAwesomeIcon key={menu.id + "icon"} style={{color: '#438AFE'}} icon={menu.icon} fontSize={20} /> <Link to={menu.url} target={menu.url.includes('https') ? '_blank' : '_self'} style={{color: theme[0].side_menu_font_color, textDecoration: 'none'}}>{menu.title}</Link><FontAwesomeIcon icon="fa-solid fa-caret-right" fontSize={12} style={{float: 'right', marginTop: '3px', textTransform: 'capitalize'}} />
                      </ListItemText>
                  </ListItem>
                </React.Fragment>
              ))}

              {/* <Divider key={Math.random()} style={{borderColor: `${theme[0].side_menu_font_color}`, borderWidth: '1px'}} />
              <Typography variant="body1" className="sideMenuItems" style={{display: 'block', color: `${theme[0].side_menu_font_color}`, textAlign: 'right'}}>Pinned Pages</Typography>
              <ListItem key={Math.random()} disablePadding className="sideMenuItems" style={{borderColor: `${theme[0].side_menu_font_color}`,padding: '12px 0'}}>
                  <ListItemText key={Math.random()} className="listItemText">
                    {isAuthenticated && <Button onClick={goToPinnedPage} style={{color: theme[0].side_menu_font_color}}><FontAwesomeIcon icon="thumbtack" fontSize="large"/> &nbsp;Case Profile</Button>}
                  </ListItemText>
              </ListItem> */}
              <Divider key="divider2" style={{borderColor: `${theme[0].side_menu_font_color}`, borderWidth: '1px'}} />

              <ListItem key="logout-list-item" disablePadding className="sideMenuItems" style={{borderColor: `${theme[0].side_menu_font_color}`,padding: '12px 0'}}>
                  <ListItemText key="logout-list-item-text" className="listItemText">
                    {isAuthenticated && <Button key="logout-button" onClick={handleLogout} style={{color: theme[0].side_menu_font_color}}><FontAwesomeIcon icon="sign-out-alt" fontSize="large" style={{color: '#438AFE'}}/> &nbsp;Logout</Button>}
                  </ListItemText>
              </ListItem>
            </List>
            
        </Box>
    </>
  );

  return (
    <div>
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}><FontAwesomeIcon style={{color: theme[0].title_bar_font_color}} icon="bars" fontSize="large"/></Button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
    </div>
  );
}
export default TemporaryDrawer;