import React, { useEffect, useState } from 'react';
import { Button, Card, Alert, Container } from 'react-bootstrap';
import { useAuth } from '../../contexts/AuthContext';
import {Link, useNavigate} from 'react-router-dom';
import { auth, db } from '../../firebase';
import useUserData from '../../hooks/usersHook';
import { useLocation } from 'react-router-dom';
import { saveAs } from 'file-saver';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const AddContacts = () => {

  let query = useQuery();
  const [testMessage, setTestMessage] = useState("");

  // let name = query.get("name");
  // let phone = query.get("phone");
  // let email = query.get("email");

  const [contact, setContact] = useState({});

  useEffect(()=>{
      setContact({
          name: query.get("name"),
          phone: query.get("phone"),
          email: query.get("email"),
        }
      )
  },[])

const isMobile = () => {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i
  ];

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
}

const downloadVCard = () => {
  //setTestMessage("This button fires");
  fetch(`https://yb8dqvs2n9.execute-api.us-east-1.amazonaws.com/prod/serveQrUrl?name=${contact.name}&phone=${contact.phone}&email=${contact.email}`)
    .then(res => res.text())
    .then(url => {
      window.open(url);
      setTestMessage(`Test fired from fetch ${contact.name}`);
  });
  
  // fetch(`https://yb8dqvs2n9.execute-api.us-east-1.amazonaws.com/prod/serveQrUrl?name=${contact.name}&phone=${contact.phone}&email=${contact.email}`)
  //   .then(res => res.blob())
  //   .then(blob => {
  //       saveAs(blob, "contact.vcf");
  //   });

}

  return (
    <Container className="align-items-center justify-content-center g-0">
        <Card>
          <Card.Body>
          <div>
            <h1>{contact.name}</h1>
            <p>{contact.phone}</p>
            <p>{contact.email}</p>
            <p>Test Message {testMessage}</p>
            <button onClick={downloadVCard}>Download vCard</button>
          </div>
          </Card.Body>
        </Card>
      </Container>
    
  );
}

export default AddContacts;
