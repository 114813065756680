import React, { createContext, useState, useEffect } from 'react';
import { Auth, Hub } from 'aws-amplify';
import { useLocation, useNavigate } from 'react-router-dom';

export const AuthContextAWS = createContext();

export const AuthProviderAWS = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(Auth.currentAuthenticatedUser() ? true : false);
  const [user, setUser] = useState(null);
  const [attributes, setAttributes] = useState(null);
  const currentUser = Auth.currentAuthenticatedUser();
  const userAttributes = Auth.getUserAttributes
  const navigate = useNavigate();
  const location = useLocation();
  function containsExactMatch(list, item) {
    const items = list.split(',').map(s => s.trim());
    return items.includes(item);
  }

  async function updateUserAttribute(attributeName, attributeValue, user) {
    try {
        // Update the attribute
        const result = await Auth.updateUserAttributes(user, {
            [attributeName]: attributeValue
        });

    } catch (error) {
        console.error('Error updating user attribute:', error);
    }
  }

  const checkAuth = async () => {
    try {
      await Auth.currentAuthenticatedUser();
      setIsAuthenticated(true);
      const userAttributes = await Auth.userAttributes();
      return userAttributes;
    } catch (error) {
      setIsAuthenticated(false);
    }
  };

  useEffect(() => {
    checkAuth();
    
    // Set up the hub listener
    const listener = Hub.listen('auth', (data) => {
      const { payload } = data;
      if (payload.event === 'signIn') {
        setIsAuthenticated(true);
        const desiredDestination = localStorage.getItem('desiredDestination') || '/';
        navigate(desiredDestination);
        localStorage.removeItem('desiredDestination'); // Clean up
      }
      if (payload.event === 'signOut') {
        setIsAuthenticated(false);
      }
    });

    // Remove the listener when the component is unmounted
    return () => Hub.remove('auth', listener);
  }, []);

  useEffect(() => {
    const getUserAttributes = async () => {
      try {
        const currentUser = await Auth.currentAuthenticatedUser();
        
        console.log("User: ", currentUser);
        const userAttributes = await Auth.userAttributes(currentUser);
        setAttributes(userAttributes);
        const clientId = localStorage.getItem("clientID");
        // Transforming the attributes array into an object
        const attributesObj = userAttributes.reduce((acc, attribute) => {
          acc[attribute.Name] = attribute.Value;
          return acc;
        }, {});
        // localStorage.setItem("userSub", attributesObj["sub"]);
        // console.log("user sub", attributesObj["sub"]);
        
  
        let currentNetworks = attributesObj["custom:clients"];
        let userRoles = attributesObj["custom:user_roles"];
        // Logging the attributes object
        const result = containsExactMatch(currentNetworks, clientId);
        if(!result) {
          let newNetworks = currentNetworks.split(',').map(s => s.trim());
          if (!currentNetworks.includes(clientId)) {
            newNetworks = currentNetworks + ', ' + clientId;
            updateUserAttribute("custom:clients", newNetworks, currentUser);
          }
        } else {
          if(userRoles == '' || userRoles == null) {
            if(location.pathname == "/edit-profile"){
              navigate("/edit-profile");
            } 
            // else {
            //   navigate("/memberships/membership-tiers");
            // }
          } else {
            navigate("/");
          }
          
        }
      } catch (error) {
        console.error('Error fetching user attributes:', error);
      }
    };
  
    if (isAuthenticated) {
      getUserAttributes();
    }
  }, [isAuthenticated]);
  
  useEffect(()=>{
    const currentUser = Auth.currentAuthenticatedUser()
    .then((user) => {
      return Auth.userAttributes(user);
    })
    .then(attributes => {
      setAttributes(attributes);
    })
  }, [])

  const signOut = async () => {
    try {
      await Auth.signOut();
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <AuthContextAWS.Provider value={{ isAuthenticated, signOut, attributes }}>
      {children}
    </AuthContextAWS.Provider>
  );
};
