import React from 'react';
import { Card, CardContent, Typography, List, ListItem, ListItemIcon, ListItemText, Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';

const Membership = ({ tier }) => {
    const clientId = localStorage.getItem("clientId");
    const navigate = useNavigate();
  const handleButtonClick = (tier) => {
    // Replace this with the code to navigate to the detail page for the corresponding tier
    console.log("payment link", tier);
    if(tier.paymentLink == '') {
        navigate('/');
    } else {
      if(tier.userRole == 'channel_owner') {
        navigate("/edit-profile");
      } else {
        window.open(tier.paymentLink, '_blank');
      }
    }
  };

  return (
    <Card sx={{ maxWidth: 300, margin: '1rem', flex: '1 1 30%', minWidth: '240px', display: 'flex', flexDirection: 'column' }}>
      <CardContent sx={{ flex: '1 0 auto' }}>
        <Typography gutterBottom variant="h5" component="div">
          {tier.title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {tier.description}
        </Typography>
        <Typography variant="h6" sx={{ mt: 2 }}>
          Price: {tier.price}
        </Typography>
        <List sx={{ mt: 2 }}>
          {tier.features.map((feature, index) => (
            <ListItem key={index}>
              <ListItemIcon>
                <FontAwesomeIcon icon={faCheckCircle} />
              </ListItemIcon>
              <ListItemText primary={feature} />
            </ListItem>
          ))}
        </List>
      </CardContent>
      <Button variant="contained" sx={{ margin: '16px' }} onClick={() => handleButtonClick(tier)}>
        Go to Registration
      </Button>
    </Card>
  );
};

export default Membership;
