import { useState, useEffect } from 'react';

const usePrograms = (endpoint) => {
    const [programs, setPrograms] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchPrograms = async () => {
            fetch(endpoint)
            .then(result=> result.json())
            .then(result=>result.programs)
            .then(result => {
                if(result.length >= 1) {
                    console.log("Programs", result);
                }
                setPrograms(result);
                setLoading(false);
            })
            .catch(err => {
                setError(err);
            });
        };

        fetchPrograms();
    }, [endpoint]);

    return { programs, loading, error };
};

export default usePrograms;