import React from 'react';
import './dashboardPremiumsPanel.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';

const premium = (amount) => amount.toString();
const firstDigit = (amount) => amount.charAt(0);
const remainingDigits = (amount) => amount.slice(1);

export const DashboardPremiumsPanel = ({ ...props }) => {
  return (
    <div className="dashboardPremiumsContainer">
      <div className='storybook-dashboardPremiumsPanel--HeaderContainer'>
        <span className='storybook-dashboardPremiumsPanel--Header'>
          Premiums Due
        </span>
        <FontAwesomeIcon
          className='ellipsis'
          icon={faEllipsisV}
          size='lg'
        />
      </div>
      <ul className='storybook-dashboardPremiumsPanel--Container'>
        {props.items.map((item, index) => (
          <li
            className='storybook-dashboardPremiumsPanel--Item'
            key={index}
          >
            <span className='storybook-dashboardPremiumsPanel--NameDate'>
              <span className='storybook-dashboardPremiumsPanel--Name'>
                {item.name}
              </span>
              <span className='storybook-dashboardPremiumsPanel--Date'>
                {item.date}
              </span>
            </span>
            <span className='storybook-dashboardPremiumsPanel--Amount'>
              <span className='storybook-dashboardPremiumsPanel--Amount--FirstDigit'>
                ${firstDigit(premium(item.amount))}
              </span>
              <span>{remainingDigits(premium(item.amount))}</span>
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};
