import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import './dashboard.scss';
import { DashboardCard } from './DashboardCard';
import { DashboardTable } from './DashboardTable';
import { DashboardMessagePanel } from './DashboardMessagePanel';
import { DashboardPremiumsPanel } from './DashboardPremiumsPannel';

const BondDashBoard = () => {
    const DailyCard = {
        header: 'paid premiums',
        type: 'paidPremiums',
        amount: '200',
        time: 'day',
        percent: '4.6',
        direction: 'up',
    }

    const YearlyCard = {
        header: 'paid premiums ytd',
        type: 'paidPremiumsYTD',
        amount: '7932',
        time: 'ytd',
        percent: '8',
        direction: 'down',
    }

    const LatePremiumsCard = {
        header: 'late premiums',
        type: 'latePremiums',
        amount: '200',
        time: 'day',
        percent: '4.6',
        direction: 'up',
    };

    const ApprovedPremiumsCard = {
        header: 'approved premiums',
        type: 'approvedPremiums',
        amount: '100',
        time: 'day',
        percent: '2.3',
        direction: 'down',
    };

    const items = [
        {
          cause: 'CPI-PB-0000-0-0',
          principal: 'Clara Smith',
          date: '09/01/2023',
          ward_estate: 'David Smith',
          attorney: 'Matt Davis',
          amount: '$100,000',
          status: ['In Progress', 'inProgress'],
        },
        {
          cause: 'CPI-PB-0000-0-0',
          principal: 'Clara Smith',
          date: '09/01/2023',
          ward_estate: 'David Smith',
          attorney: 'Matt Davis',
          amount: '$100,000',
          status: ['Approved', 'approved'],
        },
        {
          cause: 'CPI-PB-0000-0-0',
          principal: 'Clara Smith',
          date: '09/01/2023',
          ward_estate: 'David Smith',
          attorney: 'Matt Davis',
          amount: '$100,000',
          status: ['Declined', 'declined'],
        },
        {
          cause: 'CPI-PB-0000-0-0',
          principal: 'Clara Smith',
          date: '09/01/2023',
          ward_estate: 'David Smith',
          attorney: 'Matt Davis',
          amount: '$100,000',
          status: ['NMI', 'nmi'],
        },
        {
            cause: 'CPI-PB-0000-0-0',
            principal: 'Clara Smith',
            date: '09/01/2023',
            ward_estate: 'David Smith',
            attorney: 'Matt Davis',
            amount: '$100,000',
            status: ['In Progress', 'inProgress'],
          },
          {
            cause: 'CPI-PB-0000-0-0',
            principal: 'Clara Smith',
            date: '09/01/2023',
            ward_estate: 'David Smith',
            attorney: 'Matt Davis',
            amount: '$100,000',
            status: ['Approved', 'approved'],
          },
          {
            cause: 'CPI-PB-0000-0-0',
            principal: 'Clara Smith',
            date: '09/01/2023',
            ward_estate: 'David Smith',
            attorney: 'Matt Davis',
            amount: '$100,000',
            status: ['Declined', 'declined'],
          },
          {
            cause: 'CPI-PB-0000-0-0',
            principal: 'Clara Smith',
            date: '09/01/2023',
            ward_estate: 'David Smith',
            attorney: 'Matt Davis',
            amount: '$100,000',
            status: ['NMI', 'nmi'],
          },
          {
            cause: 'CPI-PB-0000-0-0',
            principal: 'Clara Smith',
            date: '09/01/2023',
            ward_estate: 'David Smith',
            attorney: 'Matt Davis',
            amount: '$100,000',
            status: ['In Progress', 'inProgress'],
          },
          {
            cause: 'CPI-PB-0000-0-0',
            principal: 'Clara Smith',
            date: '09/01/2023',
            ward_estate: 'David Smith',
            attorney: 'Matt Davis',
            amount: '$100,000',
            status: ['Approved', 'approved'],
          },
          {
            cause: 'CPI-PB-0000-0-0',
            principal: 'Clara Smith',
            date: '09/01/2023',
            ward_estate: 'David Smith',
            attorney: 'Matt Davis',
            amount: '$100,000',
            status: ['Declined', 'declined'],
          },
          {
            cause: 'CPI-PB-0000-0-0',
            principal: 'Clara Smith',
            date: '09/01/2023',
            ward_estate: 'David Smith',
            attorney: 'Matt Davis',
            amount: '$100,000',
            status: ['NMI', 'nmi'],
          },
      ]

    const messages = [
        {
          username: 'Gleene Headly',
          lastMessage: 'Design Quality is Awesome!',
          lastMessageTime: '13:30PM',
        },
        {
          username: 'Marcie E. Clark',
          lastMessage: 'Nice To Meet You Guys',
          lastMessageTime: '13:31PM',
        },
        {
          username: 'Annie J. Villareal',
          lastMessage: 'This Theme is Awesome!',
          lastMessageTime: '10:15PM',
        },
        {
          username: 'Dennis S. Vega',
          lastMessage: 'Hey! I am working on your application at the moment',
          lastMessageTime: '11:05PM',
        },
        {
          username: 'Paul R. Burns',
          lastMessage: 'I have finished! This is the last page',
          lastMessageTime: '13:28PM',
        },
    ];

    const dashboardPremiums = [
        {
          name: 'Phillip Anthropy',
          date: '12 Nov, 2020',
          amount: 159,
        },
        {
          name: 'Sir Cumference',
          date: '24 Oct, 2020',
          amount: 279,
        },
        {
          name: 'Dylan Meringue',
          date: '09 Sep, 2020',
          amount: 368,
        },
        {
          name: 'Max Conversion',
          date: '17 Aug, 2020',
          amount: 479,
        },
        {
          name: 'Bailey Wonger',
          date: '26 Aug, 2020',
          amount: 168,
        },
        {
          name: 'Sarah Scott',
          date: '7 May, 2020',
          amount: 248,
        },
      ];

    return (
        <div className="dashboardContainer">
            <div className="premiumCards">
                <DashboardCard cardDetails={LatePremiumsCard}/>
                <DashboardCard cardDetails={ApprovedPremiumsCard}/>
                <DashboardCard cardDetails={DailyCard}/>
                <DashboardCard cardDetails={YearlyCard}/>
            </div>
            <div className="dashboardRow">
                <div className="leftCol">
                    <DashboardTable items={items}/>
                </div>
                <div className="rightCol">
                    <DashboardMessagePanel items={messages}/>
                    <DashboardPremiumsPanel items={dashboardPremiums}/>
                </div>
            </div>
        </div>
    )
}

export default BondDashBoard;

