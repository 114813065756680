import React, { useEffect, useRef, useState } from 'react';
// import { makeStyles } from '@mui/material/styles';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import './page.scss'
import './channels.scss';

import './media-player.scss';

//React Player
import ReactPlayer from 'react-player'

import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import MovieCreationIcon from '@mui/icons-material/MovieCreation';

import BasicModal from '../web-components/DetailModal';
import AudioControls from './AudioControls';
import { ThemeContext } from '../App';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import MediaTracks from '../mediaTracks/MediaTracks';
import ChannelModal from '../web-components/ChannelDetailsModal';


function MediaPlayer(props) {
    const theme = React.useContext(ThemeContext);
    const baseUrl = props.baseUrl;
    const [backgroundImage, setBackgroundImage] = useState(baseUrl+props.programImage);
    // const useStyles = makeStyles((theme) => ({
    //     audioImage: {
    //         background: `url(${backgroundImage}) no-repeat`,
    //         backgroundSize: 'contain',
    //         backgroundPosition: 'center'
    //     }
    // }));
    // const classes = useStyles();
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const mediaType = props.broadcast_type;
    const trackList = props.trackList;
    // const [trackList, setTrackList] = useState(props.trackList);
    const [playing, setPlaying] = useState(true);
    const [url, setUrl] = useState(props.url);
    const [programId, setProgramId] = useState(props.programId);
    const [track, setTrack] = useState(props.track ? props.track: 0);
    const [loop, setLoop] = useState(false);
    const [programImage, setProgramImage] = useState(props.channelImage);
    const [played, setPlayed] = useState(null);
    const [duration, setDuration] = useState(0);
    const [muted, setMuted] = useState(props.broadcast_type === "video" ? true : false);
    const [volume, setVolume] = useState(null);
    const [progress, setProgress] = useState(0);
    const [rewindCount, setRewindCount] = useState(0);
    const [shareChannelId, setShareChannelId] = useState(null);
    const clientId = localStorage.getItem("clientId");
    const [channelAlias, setChannelAlias] = useState(props.channelAlias);
    // const [nextTrackOnClick, setNextTrackOnClick] = useState(props.setProgramUrl);
    // console.log("track function", nextTrackOnClick);

    // useEffect((track) => {
    //     nextTrack(track);
    // },[url])


    function nextTrack(index) {
        let trackItem = index;
        if(index < trackList.length) {
            trackItem = index + 1;
            setTrack(trackItem);
            setUrl(trackList[trackItem].url);
            setBackgroundImage(trackList[trackItem].image);
            setPlaying(true);
        } else if (index === trackList.length){
            setTrack(0);
            setPlaying(true);
            setUrl(trackList[track].url);
            setBackgroundImage(trackList[track].image);
        }  
    }

    const handleDuration = (duration) => {
        setDuration({ duration })
      }

    useEffect((trackItem) => {
        setUrl(props.url);
        setBackgroundImage(baseUrl+props.programImage);
    },[props.url, baseUrl, props.programImage, props.trackItem, props.backgroundImage])
   
    const playerRef = useRef(null);
    const handleRewind = () => {
        playerRef.current.seekTo(playerRef.current.getCurrentTime() - 10);
    }

    const handleFastForward = () => {
        playerRef.current.seekTo(playerRef.current.getCurrentTime() + 10);
    }

    const handleNextTrack = () => {
        let trackDuration = playerRef.current.getDuration();
        playerRef.current.seekTo(trackDuration);
    }

    

    async function goToPreviousTrack(currentTrack) {
        let trackItem = await props.track - 1;

        await new Promise((resolve, reject) => {
            setTrack(trackItem);
            setUrl(trackList[trackItem].url);
            setBackgroundImage(baseUrl + trackList[trackItem].image);
            setPlaying(true);
        })
    }



    const handlePreviousTrack = () => {
        if(playerRef.current.getCurrentTime() > 3) {
            playerRef.current.seekTo(0);
        } else {            
            goToPreviousTrack();
            
        }
    }

    const handleMuteOn = () => {
        setMuted(false);
    }

    const handleMuteOff = () => {
        setMuted(true);
    }

    const handleVolume = (event, level) => {
        setVolume(level);
    }

    const [value, setValue] = React.useState(.7);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const handleProgressChange = (event, newProgress) => {
        playerRef.current.seekTo(newProgress);
        setPlayed(newProgress);
        
    }

    const addToClipboard = function(channelUrl){
        console.log("copy url", channelUrl);
        navigator.clipboard.writeText(channelUrl);
    }
    
    switch(mediaType) {
        case 'embed':
            return (
                <>
                    <iframe id="mediaTrack" width='100%' title={props.title} src={props.url} frameborder='0' allowFullScreen allowtransparency="true" allow='xr-spacial-tracking' style={{'minHeight': '413px'}}></iframe>
                    <BasicModal title={props.title} author={props.author} description={props.description} />
                    <span className="addToClipboard" style={{color: theme[0].media_player_font_color}} onClick={() => addToClipboard(`https://${clientId}.shelcaster.com/broadcast/${props.channelID}`)}>Copy channel link. <ContentCopyIcon /></span>
                </>
            );

        case 'youtube':
            return (
                <>
                    <iframe id="mediaTrack" width='100%' title={props.title} src={props.url} frameborder='0' allowFullScreen allowtransparency="true" style={{'minHeight': '413px'}}></iframe>
                    <BasicModal title={props.title} author={props.author} description={props.description} />
                    <span className="addToClipboard" style={{color: theme[0].media_player_font_color}} onClick={() => addToClipboard(`https://${clientId}.shelcaster.com/broadcast/${props.channelID}`)}>Copy channel link. <ContentCopyIcon /></span>
                </>
            );

        case 'mediaTrack':
            return (
                <React.Fragment>
                    <div>
                        {props.url ? (<ReactPlayer 
                            id="mediaPlayer"
                            className="mediaPlayer"
                            ref={playerRef}
                            onProgress={(played) => {
                                setPlayed(played.playedSeconds);
                            }}
                            onDuration={handleDuration}
                            onRewind={handleRewind}
                            onFastForward={handleFastForward}
                            onNextTrack={handleNextTrack}
                            loop={loop} 
                            playing={playing} 
                            // className={classes.audioImage} 
                            volume={value}
                            step={.1}
                            muted={muted}
                            // controls 
                            url={url} 
                            width='100%' 
                            height="1px" 
                            onEnded={() => props.getTrackDetails(trackList, props.track)} 
                            // style={{backgroundImage: `url(${backgroundImage})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}}
                        />) : (
                            // <Skeleton width="100%" variant="rect" height={360}>Shelcaster</Skeleton>
                            <img src="https://shelcaster.tv/images/slider-shelcaster.jpg" width="100%" alt="A Shelcaster Network"/>
                        )}
                    </div>
                    <AudioControls
                        isPlaying={playing}
                        // onPrevClick={toPrevTrack}
                        // onNextClick={toNextTrack}
                        onPlayPauseClick={setPlaying}
                        played={played}
                        duration={duration}
                        handleRewind={handleRewind}
                        handleFastForward={handleFastForward}
                        onMute={handleMuteOn}
                        offMute={handleMuteOff}
                        muted={muted}
                        volume={volume}
                        handleVolume={handleVolume}
                        handleChange={handleChange}
                        value={value}
                        progress={progress}
                        handleProgressChange={handleProgressChange}
                        handleNextTrack={handleNextTrack}
                        handlePreviousTrack={handlePreviousTrack}
                    />
                    <BasicModal title={props.title} author={props.author} description={props.description} />
                    {/* <span className="addToClipboard" style={{color: theme[0].media_player_font_color}} onClick={() => addToClipboard(`https://${clientId}.shelcaster.com/broadcast/${props.channelID}`)}>Copy channel link. <ContentCopyIcon /></span> */}
                </React.Fragment>
            );
            break;

        case 'audio':
            return (
                <React.Fragment>
                    <div>
                        {props.url ? (<ReactPlayer 
                            id="mediaPlayer"
                            className="mediaPlayer"
                            ref={playerRef}
                            onProgress={(played) => {
                                setPlayed(played.playedSeconds);
                            }}
                            onDuration={handleDuration}
                            onRewind={handleRewind}
                            onFastForward={handleFastForward}
                            onNextTrack={handleNextTrack}
                            loop={loop} 
                            playing={playing} 
                            // className={classes.audioImage} 
                            volume={value}
                            step={.1}
                            muted={muted}
                            // controls 
                            url={url} 
                            width='100%' 
                            height="425px" 
                            onEnded={() => props.getTrackDetails(trackList, props.track)} 
                            style={{backgroundImage: `url(${backgroundImage})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}}
                        />) : (
                            // <Skeleton width="100%" variant="rect" height={360}>Shelcaster</Skeleton>
                            <img src="https://shelcaster.tv/images/slider-shelcaster.jpg" width="100%" alt="A Shelcaster Network"/>
                        )}
                    </div>
                    <AudioControls
                        isPlaying={playing}
                        // onPrevClick={toPrevTrack}
                        // onNextClick={toNextTrack}
                        onPlayPauseClick={setPlaying}
                        played={played}
                        duration={duration}
                        handleRewind={handleRewind}
                        handleFastForward={handleFastForward}
                        onMute={handleMuteOn}
                        offMute={handleMuteOff}
                        muted={muted}
                        volume={volume}
                        handleVolume={handleVolume}
                        handleChange={handleChange}
                        value={value}
                        progress={progress}
                        handleProgressChange={handleProgressChange}
                        handleNextTrack={handleNextTrack}
                        handlePreviousTrack={handlePreviousTrack}
                    />
                    <BasicModal title={props.title} author={props.author} description={props.description} />
                    {/* <span className="addToClipboard" style={{color: theme[0].media_player_font_color}} onClick={() => addToClipboard(`https://${clientId}.shelcaster.com/broadcast/${props.channelID}`)}>Copy channel link. <ContentCopyIcon /></span> */}
                </React.Fragment>
            );
            break;

            case 'video':
            return (
                <React.Fragment>
                    <ThemeContext.Provider value={theme}>
                    <div>
                        {props.url ? (<ReactPlayer 
                            id="mediaPlayer"
                            className="mediaPlayer"
                            ref={playerRef}
                            onProgress={(played) => {
                                setPlayed(played.playedSeconds);
                            }}
                            onDuration={handleDuration}
                            onRewind={handleRewind}
                            onFastForward={handleFastForward}
                            onNextTrack={handleNextTrack}
                            loop={loop} 
                            playing={playing} 
                            // className={classes.audioImage} 
                            volume={value}
                            step={.1}
                            muted={muted}
                            // controls 
                            url={url} 
                            width='100%' 
                            height="425px" 
                            onEnded={() => props.getTrackDetails(trackList, props.track)} 
                            style={{backgroundImage: `url(${backgroundImage})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}}
                        />) : (
                            // <Skeleton width="100%" variant="rect" height={360}>Shelcaster</Skeleton>
                            <img src="https://shelcaster.tv/images/slider-shelcaster.jpg" width="100%" alt="A Shelcaster Network"/>
                        )}
                    </div>
                    <AudioControls
                        isPlaying={playing}
                        // onPrevClick={toPrevTrack}
                        // onNextClick={toNextTrack}
                        onPlayPauseClick={setPlaying}
                        played={played}
                        duration={duration}
                        handleRewind={handleRewind}
                        handleFastForward={handleFastForward}
                        onMute={handleMuteOn}
                        offMute={handleMuteOff}
                        muted={muted}
                        volume={volume}
                        handleVolume={handleVolume}
                        handleChange={handleChange}
                        value={value}
                        progress={progress}
                        handleProgressChange={handleProgressChange}
                        handleNextTrack={handleNextTrack}
                        handlePreviousTrack={handlePreviousTrack}
                    />
                    <ChannelModal title={props.title} author={props.author} description={props.description} />
                    {<span className="addToClipboard" style={{color: theme[0].title_bar_font_color}} onClick={() => addToClipboard(`https://${clientId}.shelcaster.com/broadcast/${props.channelAlias}`)}>Copy channel link. <ContentCopyIcon /></span>}
                    </ThemeContext.Provider>
                </React.Fragment>
            );
            break; 
        default:
            return (
                <React.Fragment>
                    <div>
                        {props.url ? (<ReactPlayer 
                            id="mediaPlayer"
                            ref={playerRef}
                            onProgress={(played) => {
                                setPlayed(played.playedSeconds);
                            }}
                            onDuration={handleDuration}
                            onRewind={handleRewind}
                            onFastForward={handleFastForward}
                            loop={loop} 
                            playing={playing} 
                            // className={classes.audioImage} 
                            volume={value}
                            step={.1}
                            muted={muted}
                            controls 
                            url={url} 
                            width='100%' 
                            height="425px" 
                            onEnded={() => props.getTrackDetails(trackList, props.track)} 
                            style={{backgroundImage: `url(${backgroundImage})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}}
                        />) : (
                            // <Skeleton width="100%" variant="rect" height={360}>Shelcaster</Skeleton>
                            <img src="https://shelcaster.tv/images/slider-shelcaster.jpg" width="100%" alt="A Shelcaster Network"/>
                        )}
                    </div>
                    <BasicModal title={props.title} author={props.author} description={props.description} />
                    {/* <span className="addToClipboard" onClick={() => addToClipboard(`https://${clientId}.shelcaster.com/broadcast/${props.channelID}`)}>Copy channel link. <ContentCopyIcon /></span> */}
                </React.Fragment>
            );
    }
}

export default MediaPlayer;