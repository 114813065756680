import React, { useState, useContext, useEffect } from 'react';
import { TextField, Button, Select, MenuItem, InputLabel, FormControl, Checkbox, FormControlLabel, Radio, RadioGroup, Chip } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ThemeContext } from '../../App';
import { API } from 'aws-amplify';
import useChannels from '../../../hooks/useChannels';
import AWS from 'aws-sdk';

// Configure AWS SDK (e.g., set your region and credentials)
AWS.config.update({
    region: process.env.REACT_APP_REGION, // for example, 'us-west-2'
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY
});

const ProgramBuilder = (props)=> {
    const clientId = localStorage.getItem("clientID");
    const baseUrl = localStorage.getItem("baseUrl");
    const bucketName = process.env.REACT_APP_BUCKET_NAME;
    const apiEndpointChannels = `${process.env.REACT_APP_CHANNEL_ENDPOINT}?network=${clientId}`; 
    const apiEndpointProgramBuilder = `${process.env.REACT_APP_PROGRAM_BUILDER_ENDPOINT_LAMBDA}`;
    const { channels, loading, error } = useChannels(apiEndpointChannels);
    console.log("Channels in program builder", channels);
    const [channelList, setChannelList] = useState(null);
    // console.log("categories in builder", categories);
    const theme = useContext(ThemeContext);
    const s3 = new AWS.S3();

    const uploadToS3 = (file) => {
        return new Promise((resolve, reject) => {
            const params = {
                Bucket: process.env.REACT_APP_BUCKET_NAME,
                Key: `${Date.now()}-${file.name}`, // Prefixing with Date.now() to avoid overwriting existing files
                Body: file,
                ContentType: file.type, // Set the appropriate content type for your file
                ACL: 'public-read' // If you want the file to be publicly accessible; remove or adjust as necessary
            };

            s3.upload(params, (err, data) => {
                if (err) {
                    console.error('Error uploading file:', err);
                    reject(err);
                } else {
                    console.log('Successfully uploaded file:', data.Location);
                    resolve(data.Key); // You can also resolve with data.Location if you want the full URL
                }
            });
        });
    };

    const [formData, setFormData] = useState({
        title: {value: '', type: 'TextField'},
        state: { value: '1', type: 'Select', options: ['1', '0']},
        description: {value: '', type: 'TextField'},
        media_file: {value: '', type: 'File'},
        url: {value: '', type: 'TextField'},
        upload_url: {value: '', type: 'TextField'},
        upload_directory: {value: '', type: 'TextField'},
        author: {value: '', type: 'TextField'},
        file_type: { value: '', type: 'Select', options: []},
        broadcast_type: { value: '', type: 'Select', options: ['Video', 'Audio', 'Media Tracks', 'Embed']},
        embed_code: {value: '', type: 'TextField'},
        premium: {value: '', type: 'TextField'},
        ppv_cost: {value: '', type: 'TextField'},
        image: {value: '', type: 'File'},
        image_thumb: {value: '', type: 'File'},
        channels: { value: [], type: 'Select', options: []},
        fun_facts: { value: '', type: 'Select', options: []},
        store_url: {value: '', type: 'TextField'},
        ordering: {value: 0, type: 'Number'},
        program_owner: props.userId,
    });
    useEffect(()=>{
        setChannelList(channels);
        console.log("form data", channelList);
    },[channels])
    
    const formInput = {
        title: formData.title.value,
        state: formData.state.value,
        description: formData.description.value,
        media_file: formData.media_file.value,
        url: formData.url.value,
        upload_url: formData.upload_url.value,
        upload_directory: formData.upload_directory.value,
        author: formData.author.value,
        file_type: formData.file_type.value,
        broadcast_type: formData.broadcast_type.value,
        embed_code: formData.embed_code.value,
        premium: formData.premium.value,
        ppv_cost: formData.ppv_cost.value,
        image: formData.image.value,
        image_thumb: formData.image_thumb.value,
        channels: formData.channels.value,
        fun_facts: formData.fun_facts.value,
        store_url: formData.store_url.value,
        ordering: formData.ordering.value,
        network: clientId,
        program_owner: props.userId,
        bucket_name: bucketName,
    }
    const handleSubmit = async (e) => {
        // console.log("form input", formInput);
        e.preventDefault();
        try {
            const response = await fetch(apiEndpointProgramBuilder,{
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formInput),
            });
            const responseData = await response.json();
            console.log('Success2:', responseData);
            setFormData({
                title: {value: '', type: 'TextField'},
                state: { value: '1', type: 'Select', options: ['1', '0']},
                description: {value: '', type: 'TextArea'},
                media_file: {value: '', type: 'File'},
                url: {value: '', type: 'TextField'},
                upload_url: {value: '', type: 'TextField'},
                upload_directory: {value: '', type: 'TextField'},
                author: {value: '', type: 'TextField'},
                file_type: { value: '', type: 'Select', options: []},
                broadcast_type: { value: '', type: 'Select', options: ['Video', 'Audio', 'Media Tracks', 'Embed']},
                embed_code: {value: '', type: 'TextArea'},
                premium: {value: '', type: 'TextField'},
                ppv_cost: {value: '', type: 'TextField'},
                image: {value: '', type: 'File'},
                image_thumb: {value: '', type: 'File'},
                channels: { value: [], type: 'Select', options: []},
                fun_facts: { value: '', type: 'Select', options: []},
                store_url: {value: '', type: 'TextField'},
                ordering: {value: 0, type: 'Number'},
            });
        } catch (error) {
            console.error('Error saving form data', error);
        };
        
    };
    

    

    const handleChange = (e) => {
        const { name, value } = e.target;
        const isMultiple = e.target.multiple; // Check if it's a multiple select
    
        let updatedValue;
    
        if (isMultiple) {
            // If it's a multiple select, ensure value is an array
            updatedValue = Array.from(e.target.selectedOptions).map(option => option.value);
        } else {
            updatedValue = value;
        }
        console.log("category array", updatedValue);
    
        setFormData(prevData => ({
            ...prevData,
            [name]: { ...prevData[name], value: updatedValue }
        }));
    };
    

    const handleFileChange = async (event) => {
        console.log("file", event);
        const { name, files } = event.target;
        if (files.length) {
            // Step 1: Upload to S3
            const file = files[0];
            try {
                const s3Key = await uploadToS3(file); // Correctly call the function and use await to get the promise result
                
                // Step 2: Store the S3 Key in your form data (or save to DynamoDB)
                setFormData(prevData => ({
                    ...prevData,
                    [name]: { ...prevData[name], value: s3Key },
                    s3Key: s3Key
                }));
            } catch (error) {
                console.error("Error uploading the file:", error);
            }
        }
    };
    useEffect(() => {
        console.log("updated form data", formData);
    }, [formData]);
    
    

    return (
        <>
            <h4>Program Builder</h4>
            <div style={{display: 'flex', flexDirection: 'column', backgroundColor: '#fff', padding: '6px', marginTop: '6px', color: 'rgba(0, 0, 0, 0.87)'}}>
                <form onSubmit={handleSubmit} style={{display: 'flex', flexDirection: 'column'}}>
                    {Object.entries(formData).map(([key, field]) => {
                        const label = key.replace(/_/g, ' ').charAt(0).toUpperCase() + key.slice(1);
                        switch (field.type) {
                            case 'TextField':
                                return (
                                    <TextField
                                        key={key}
                                        name={key}
                                        label={label}
                                        value={field.value}
                                        onChange={handleChange}
                                        fullWidth
                                        margin="dense"
                                    />
                                );
                            case 'TextArea':
                                return (
                                    <TextField
                                        key={key}
                                        name={key}
                                        label={label}
                                        value={field.value}
                                        onChange={handleChange}
                                        fullWidth
                                        margin="dense"
                                        multiline
                                    />
                                );
                            case 'Number':
                                return (
                                    <TextField
                                        key={key}
                                        name={key}
                                        label={label}
                                        value={field.value}
                                        onChange={handleChange}
                                        fullWidth
                                        margin="dense"
                                        type="number"
                                    />
                                );
                            case 'Select':
                                if (key === "channels") {
                                    return (
                                        <FormControl key={key} fullWidth margin="none">
                                            <InputLabel id={`${key}-label`}>{label}</InputLabel>
                                            <Select
                                                labelId={`${key}-label`}
                                                name={key}
                                                value={field.value}
                                                onChange={handleChange}
                                                multiple
                                            >
                                                {channels.map(option => (
                                                    <MenuItem key={option.id} value={option.id}>
                                                        {option.title}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    );
                                } else {
                                    return (
                                        <FormControl key={key} fullWidth margin="none">
                                            <InputLabel id={`${key}-label`}>{label}</InputLabel>
                                            <Select
                                                labelId={`${key}-label`}
                                                name={key}
                                                value={field.value}
                                                onChange={handleChange}
                                            >
                                                {field.options.map(option => (
                                                    <MenuItem key={option} value={option}>
                                                        {option}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    );
                                }
                            case 'Checkbox':
                                return (
                                    <FormControlLabel
                                        key={key}
                                        control={
                                            <Checkbox
                                                name={key}
                                                checked={field.value}
                                                onChange={(e) => handleChange({ target: { name: key, value: e.target.checked } })}
                                            />
                                        }
                                        label={label}
                                    />
                                );
                            case 'File':
                                return (
                                    <label>
                                    Upload {label}
                                    <input
                                        type="file"
                                        name={key}
                                        onChange={handleFileChange}
                                    />
                                    </label>
                                );
                            case 'TextArea':
                                return (
                                    <TextField
                                        key={key}
                                        name={key}
                                        label={label}
                                        value={field.value}
                                        onChange={handleChange}
                                        fullWidth
                                        margin="dense"
                                        multiline
                                        minRows={4}
                                    />
                                );
                            case 'Tags':
                                return (
                                    <Autocomplete
                                        multiple
                                        key={key}
                                        id={`${key}-tags-filled`}
                                        options={field.options ? field.options.map((option) => option.title):''}
                                        freeSolo
                                        value={field.value}
                                        onChange={(event, newValue) => {
                                            setFormData(prevData => ({
                                                ...prevData,
                                                [key]: { ...prevData[key], value: newValue }
                                            }));
                                        }}
                                        renderTags={(value, getTagProps) =>
                                            value.map((option, index) => (
                                                <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                            ))
                                        }
                                        renderInput={(params) => (
                                            <TextField {...params} variant="filled" label={label} placeholder="Add a tag" fullWidth margin="dense" />
                                        )}
                                    />
                                );
                            default:
                                return null;
                        }
                    })}
                    <Button type="submit" variant="contained" color="primary">
                        Submit
                    </Button>
                </form>
            </div>
        </>
    );
}

export default ProgramBuilder;
