import React from 'react';
import PropTypes from 'prop-types';
import './DirectoryCard.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export const DirectoryCard = ({backgroundColor, padding, ...props}) => {
    const items = props.directory.args;
    return(
        <div className="card" style={{minWidth: items.minWidth, border: items.border, borderRadius: items.borderRadius, overflow: items.borderRadius != null ? 'hidden': 'auto'}}>
            {items.cardHeader && <div className="cardHeader" style={{backgroundColor: items.headerBackgroundColor, color: items.headerFontColor, padding: padding}}>
                <div className="headerRow1">
                    <div className="column1">Col 1</div>
                    <div className="column2">Col 2</div>
                </div>
            </div>}
            {items.cardContent && <div className={`cardContent ${props.type}`} style={{color: items.contentFontColor, padding: padding}}>
                <div className="cardAvatar"><img src={items.avatar}/></div>
                <div className="cardContentHeadWrapper">
                    <div className="cardContentHead">
                        <div className="column1">
                            <span className="title">{items.firstName} Holt</span><br/>
                            <span className="subTitle">Administrator</span>
                        </div>
                        <div className="column2">
                        <FontAwesomeIcon icon="fa-solid fa-pencil" style={{cursor:'pointer'}}/>
                        </div>
                    </div>
                    <div className="cardContentBody">
                        <ul className="cardUserDetails">
                            <li>jared@cpibonding.com</li>
                        </ul>
                    </div>
                </div>
            </div>}
            <div className="cardFooter" style={{backgroundColor: props.footerBackgroundColor, color: props.footerFontColor, padding: padding}}>
                <div className="column1"><span className="cardFooterLabels">Open Tasks</span><br/>010</div>
                <div className="column2"><span className="cardFooterLabels">Messages</span><br/>010</div>
                <div className="column3"><span className="cardFooterLabels">Last Login</span><br/>00/00/00</div>
            </div>
        </div>
    )
}

DirectoryCard.propTypes = {
    backgroundColor: PropTypes.string
}

DirectoryCard.defaultProps = {
    backgroundColor: '#ccc'
}