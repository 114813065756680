import React, { useEffect, useState } from 'react';
import { TextField, Button, Select, MenuItem, InputLabel, FormControl, Checkbox, FormControlLabel, Radio, RadioGroup, Chip, Card, CardMedia, CardContent, Typography } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import './captureUserForm.scss';

const CaptureUserForm = () => {
    const clientId = localStorage.getItem("clientId");
    const baseUrl = localStorage.getItem("baseUrl");
    const { floor_plan_id, floor_plan_image, door_code } = useParams();
    const [doorCode, setDoorCode] = useState(null);
    const imageArray = floor_plan_image.split("/");
    const floorPlanImage = imageArray.pop();
    const [formData, setFormData] = useState({
        firstName: {value: '', type: 'TextField', isRequired: true},
        lastName: {value: '', type: 'TextField', isRequired: true},
        email: {value: '', type: 'TextField', isRequired: true},
        phone: {value: '', type: 'TextField', isRequired: true},
    })

    const formInput = {
        firstName: {value: formData.firstName.value},
        lastName: {value: String(formData.lastName.value)},
        email: {value: formData.email.value},
        phone: {value: formData.phone.value},
        network: clientId,
        floorPlan: floor_plan_id,
    }    

    const apiCollectUserInfo = process.env.REACT_APP_COLLECT_USER_INFO;

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        // Check if all required fields are filled out
        // const requiredFields = ['firstName', 'lastName', 'email', 'phone'];
        // const isFormValid = requiredFields.every(field => Boolean(formInput[field].value));
    
        // if (!isFormValid) {
        //     alert('Please fill out all required fields');
        //     return;
        // }
    
        try {
            const response = await fetch(apiCollectUserInfo, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Origin': '*',
                },
                body: JSON.stringify(formInput),
            });
            const responseData = await response.json();
            console.log('Success2:', responseData);
    
            // Clear the form after submission
            setFormData({
                firstName: { value: '', type: 'TextField' },
                lastName: { value: '', type: 'TextField' },
                email: { value: '', type: 'TextField' },
                phone: { value: '', type: 'TextField' },
            });
            setDoorCode(door_code);
        } catch (error) {
            console.error('Error saving form data', error);
        }
    };
    

    const handleChange = (e) => {
        const { name, value } = e.target;
        const isMultiple = e.target.multiple; // Check if it's a multiple select
    
        let updatedValue;
    
        if (isMultiple) {
            // If it's a multiple select, ensure value is an array
            updatedValue = Array.from(e.target.selectedOptions).map(option => option.value);
        } else {
            updatedValue = value;
        }
    
        setFormData(prevData => ({
            ...prevData,
            [name]: { ...prevData[name], value: updatedValue }
        }));
    };


    return (
    <div>
        <h1>Learn more about this home</h1>
        {floor_plan_id && <h3>Floor Plan ID: {floor_plan_id}</h3>}
        {!doorCode && <form onSubmit={handleSubmit} style={{display: 'flex', flexDirection: 'column'}}>
            {Object.entries(formData).map(([key, field]) => {
                const label = key.replace(/_/g, ' ').charAt(0).toUpperCase() + key.slice(1);
                // Determine if a field is required based on your logic
                // const isRequired = key === 'firstName' || key === 'lastName'; // Update with your logic
                switch (field.type) {
                    case 'TextField':
                        return (
                            <>
                                <TextField
                                    key={key}
                                    name={key}
                                    label={label}
                                    value={field.value}
                                    onChange={handleChange}
                                    fullWidth
                                    margin="none"
                                    required={field.isRequired}
                                />
                            </>
                        );
                    default: 
                        return null;
                }
            })}
            <Button type="submit" variant="contained" color="primary">
                Submit
            </Button>
        </form>}
        <Card>
            {doorCode && <CardContent>
                <Typography variant="h3">
                    Door Code: {door_code}
                </Typography>
            </CardContent>}
            <CardMedia
                component="img"
                sx={{ width: 120 }}
                image={`${baseUrl}images/plans/${floorPlanImage}`}
                alt="Live from space album cover"
            />
        </Card>
    </div>
  );
}
export default CaptureUserForm;
