import React from 'react';
import Membership from './Membership';

const tiers = [
  {
    title: 'Free Membership',
    description: 'Subscribe to receive exclusive benefits and support your favorite artists',
    features: ['Personal vCard'],
    price: 'Free',
    paymentLink: 'edit-profile',
    userRole: 'member-free'
  },
  {
    title: 'Artists',
    description: 'Join for free to turn your fan base into your customer base with your own monetized channel.',
    features: [
      'Create your channel',
      'Upload audio, video, or interactive media (embed code)',
      'Invite your fans',
      'Receive subscription revenue',
    ],
    price: 'Free',
    paymentLink: '/edit-profile',
    userRole: 'channel_owner',
  },
  {
    title: 'Silver Membership',
    description: 'Subscribe to receive exclusive benefits and support your favorite artists',
    features: ['5 premium channels', 'Exclusive artist content'],
    price: '$9.99/month',
    paymentLink: 'https://buy.stripe.com/test_4gw9Ei5J2dGxays144',
    userRole: 'member-silver'
  },
  {
    title: 'Gold Membership',
    description: 'Subscribe to receive exclusive benefits and support your favorite artists',
    features: ['10 premium channels', 'Exclusive artist content'],
    price: '$14.99/month',
    paymentLink: 'https://buy.stripe.com/cN216sgg3ca4bVS007',
    userRole: 'member-gold',
  },
  {
    title: 'Platinum Membership',
    description: 'Subscribe to receive exclusive benefits and support your favorite artists',
    features: ['15 premium channels', 'Exclusive artist content', 'Custom playlists', 'VIP opportunities'],
    price: '$19.99/month',
    paymentLink:'https://buy.stripe.com/7sI02oaVJ8XScZW9AI',
    userRole: 'member-platinum'
  },
];

const MembershipTiers = () => {
  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
      {tiers.map((tier, index) => (
        <Membership key={index} tier={tier} />
      ))}
    </div>
  );
};

export default MembershipTiers;