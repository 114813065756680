import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import UploadForm from '../admin/storage/UploadForm';
import { ThemeContext } from '../App';
import ChannelBuilder from '../admin/broadcast/ChannelBuilder';
import ChannelCategoryBuilder from '../admin/broadcast/ChannelCategoryBuilder';
import ProgramBuilder from '../admin/broadcast/ProgramBuilder';
import { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function UserTabs(props) {
  console.log("category API in userTabs", props.categoryAPI);
    const theme = useContext(ThemeContext);
    const [userAttributes, setUserAttributes] = useState(null);
    const [channelOwner, setChannelOwner] = useState(false);
    const [superAdmin, setSuperAdmin] = useState(false);
    const [userId, setUserId] = useState(null);
    useEffect(() => {
      const fetchUserAttributes = async () => {
        try {
          const user = await Auth.currentAuthenticatedUser();
          const attributes = await Auth.userAttributes(user);
          setUserAttributes(attributes);
          const userIdAttribute = attributes.find(attr => attr.Name === 'sub');
          setUserId(userIdAttribute ? userIdAttribute.Value : null);
          
          const userRoleAttribute = attributes.find(attr => attr.Name === 'custom:user_role');
          const userRole = userRoleAttribute ? userRoleAttribute.Value : null;
          
          if(userRole) {
            // console.log("User Role:", userRole);
            if(userRole == "channel_owner"){
              setChannelOwner(true);
              localStorage.setItem("channelOwner", true);
            }
            if(userRole == "super_admin"){
              setSuperAdmin(true);
              localStorage.setItem("superAdmin", true);
            }
          }
          
          
          // If you want to save the role to a state
          
    
        } catch (error) {
          console.error("Error fetching user attributes", error);
        }
      };
      fetchUserAttributes();
    }, []);
    
    const tabs = [
        // { label: 'Channel Categories', component: <ChannelCategoryBuilder userId={userId}/>},
        { label: 'Channel Builder', component: <ChannelBuilder userId={userId} categoryAPI={props.categoryAPI} /> },
        { label: 'Program Builder', component: <ProgramBuilder userId={userId} />},
        { label: 'Media Uploader', component: <UploadForm userId={userId} /> },
    ];
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      {channelOwner && <Box style={{backgroundColor: theme[0].page_background_color, color: theme[0].page_font_color, padding: '12px', width: '100%'}}>
        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" style={{justifyItems: 'space-between'}}>
          {tabs.map((tab, index) => (
            <Tab style={{color: theme[0].page_font_color, padding: '12px 16px'}} key={index} label={tab.label} />
          ))}
        </Tabs>
        {tabs.map((tab, index) => (
          <TabPanel key={index} value={value} index={index}>
            {tab.component}
          </TabPanel>
        ))}
      </Box>}
    </>
  );
}
