import React, { useContext, useEffect } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import useChannelCategories from '../../../hooks/useChannelCategories';
import useChannels from '../../../hooks/useChannels';
import usePrograms from '../../../hooks/usePrograms';
import { Auth } from 'aws-amplify';
import Playlist from '../Playlist';
import { Card, CardContent, CardMedia, Typography, CardActions, Button, Link as MuiLink, ListItem, List, Container, Grid, Popover, Skeleton } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro'

import { ThemeContext } from '../../App';

const MediaCenter = ()=>{
    const theme = useContext(ThemeContext);
    const {categories, loading, error } = useChannelCategories(process.env.REACT_APP_CHANNEL_CATEGORIES_ENDPOINT_PHP);
    const clientId = localStorage.getItem("clientId");
    const channels = useChannels(`${process.env.REACT_APP_CHANNEL_ENDPOINT}?network=${clientId}`);
    const programs = usePrograms(`${process.env.REACT_APP_PROGRAM_LIST_ENDPOINT}?network=${clientId}`);
    // Helper function to get channel data by ID
    const getChannelById = channelId => {
        return channels.find(channel => channel.id === channelId);
    };
    console.log("Channels", channels.channels);
    console.log("programs", programs.programs);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;
    return (
        <>
            <div id="channelContainer" style={theme ? {maxWidth: '100%', color: theme[0].title_bar_font_color ? theme[0].title_bar_font_color : theme[0].primary_color}: null}>
                <Container className="mainBody" style={theme ? {maxWidth: '100%',  height: '100%', minHeight: '800px', paddingTop: '24px'} : null}>
                    <ul>
                        {categories && categories.map(category => (
                            <li key={category.id}>
                                {category.title} - {category.id}
                                <ul>
                                    {channels.channels.filter(channel => channel.categories.includes(category.id))
                                    .map(channel => {
                                        return (
                                            <li key={channel.id}>
                                                <ul>
                                                {channel.title}
                                                
                                                </ul>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </li>
                        ))}
                    </ul>
                </Container>
            </div>
        </>
    )
}

export default MediaCenter;