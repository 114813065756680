import React from 'react';
import './reportsTable.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const ReportsTable = ({ size, ...props }) => {
  return (
    <div className="dashboardTableContainer">
      <table className='storybook-dashboardTable'>
        <tr className='storybook-dashboardTable--header'>
          <th className='storybook-dashboardTable--header--cause'>Print</th>
          <th className='storybook-dashboardTable--header--prinicpal'>
            Principal
          </th>
          <th className='storybook-dashboardTable--header--date'>Static Reports</th>
          <th className='storybook-dashboardTable--header--ward_estate'>
            Value
          </th>
          <th className='storybook-dashboardTable--header--attorney'>
            Priority
          </th>
          <th className='storybook-dashboardTable--header--amount'>Shortcut</th>
        </tr>
        {props.items.map((item, index) => (
          <tr
            className='storybook-dashboardTableRow'
            key={index}
          >
            <td
              className={[
                'storybook-dashboardTable--cause',
                'storybook-dashboardTable--data',
              ].join(' ')}
            >
              <FontAwesomeIcon icon={item.icon} style={{cursor: 'pointer'}} />
            </td>
            <td className='storybook-dashboardTable--data'>{item.staticReports}</td>
            <td className='storybook-dashboardTable--data'>{item.dates}</td>
            <td className='storybook-dashboardTable--data'>
              {item.value}
            </td>
            <td className='storybook-dashboardTable--data' style={{color: item.priorityColor}}>{item.priority}</td>
            <td
              className={[
                'storybook-dashboardTable--data',
                'storybook-dashboardTable--status',
              ].join(' ')}
            >
              <span className={`storybook-dashboardTable--${item.shortcut[0]}`} style={{backgroundColor: item.shortcut[1], padding: '6px', borderRadius: '5px'}}>
                {item.shortcut[0]}
              </span>
            </td>
          </tr>
        ))}
      </table>
    </div>
  );
};
