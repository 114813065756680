import React, {useRef} from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { Container, Grid, ListItemButton, ListItemIcon, ListItemSecondaryAction, Popover, Skeleton, Typography } from '@mui/material';
import { ThemeContext } from '../App';
import './playlist.scss';
import styles from './playlist.scss';
import SignalCellularNullIcon from '@mui/icons-material/SignalCellularNull';
import SoundBars from './SoundBars';
import { Image } from 'react-bootstrap';
import './mediaTrackView.scss';
import DownloadIcon from '@mui/icons-material/Download';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import Mp3DownloadLink from '../utility_components/Mp3DownloadLink';

const MediaTracksView = (props) => {
    console.log("items from accordion", props.items);
    const theme = React.useContext(ThemeContext);
    const currentTrackRef = useRef();
    const baseUrl = localStorage.getItem('baseUrl');
    return(
        <React.Fragment>
            {props.items && props.items.map((item, i) => (
                <ListItem id={i} onClick={() => {props.setProgramUrl(item, i)}} className="playlistItems mediaPlaylistItems" key={i} style={{alignItems: "flex-start", color:theme[0].playlist_font_color}}>
                    <ListItemAvatar className="avatarImg" style={{position:'relative'}}>
                        <Avatar alt={item.title} src={`${baseUrl+item.image}`} />
                        <PlayCircleFilledWhiteIcon className="playCircle" />
                    </ListItemAvatar>
                    <ListItemText
                        primary={
                            <div>
                                <Typography
                                    component="span"
                                    variant="body2"
                                    
                                    className="trackTitle"
                                    // color= {theme[0].playlist_font_color} 
                                    
                                    // className={classes.inline}
                                    onClick={() => {props.setProgramUrl(item, i)}}
                                >
                                    <span ref={currentTrackRef} className="playlistTitle">{item.title}</span>
                                </Typography>
                            </div>
                        }
                        
                    />
                    <ListItemIcon style={{alignSelf: 'center'}}>
                        <Mp3DownloadLink href={item.url} fileName={`${item.title}.mp3`} />
                    </ListItemIcon>
                    <ListItemIcon style={{alignSelf: 'center'}}>
                        <AddShoppingCartIcon/>
                    </ListItemIcon>
                    <ListItemIcon style={{alignSelf: 'center'}}>
                        <SoundBars/>
                    </ListItemIcon>
                </ListItem>
        ))}
        </React.Fragment>
    )

}
export default MediaTracksView;