import React from 'react';
import './dashboardMessagePanel.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV, faRotateRight } from '@fortawesome/free-solid-svg-icons';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';

const truncateText = (text, limit) => {
  if (text.length <= limit) return text;
  return `${text.slice(0, limit)}...`;
};

function stringToColor(string) {
    let hash = 0;
    let i;
  
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
  
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
  
    return color;
}

function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
}

export const DashboardMessagePanel = ({ ...props }) => {
  return (
    <div className="dashboardMessagesContainer">
      <div className='storybook-dashboardMessagePanel--HeaderContainer'>
        <span className='storybook-dashboardMessagePanel--Header'>
          Messages
        </span>
        <span>
          <FontAwesomeIcon
            className='refresh'
            icon={faRotateRight}
            size='lg'
          />
          <FontAwesomeIcon
            className='ellipsis'
            icon={faEllipsisV}
            size='lg'
          />
        </span>
      </div>
      <ul className='storybook-dashboardMessagePanel--Container'>
        {props.items.map((item, index) => (
          <li
            className='storybook-dashboardMessagePanel--Item'
            key={index}
          >
            {/* <img
              className='storybook-dashboardMessagePanel--UserAvatar'
              src={item.avatar}
              alt={item.username}
            /> */}
            <Avatar {...stringAvatar(item.username)} />
            <span className='storybook-dashboardMessagePanel--UserMessage'>
              <span className='storybook-dashboardMessagePanel--Username'>
                {item.username}
              </span>
              <span className='storybook-dashboardMessagePanel--Message'>
                {truncateText(item.lastMessage, 25)}
              </span>
            </span>
            <span className='storybook-dashboardMessagePanel--MessageTime'>
              {item.lastMessageTime}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};
