import React, { useState } from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import './bondStep1.scss';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router';

const BondStep1 = () => {
    const [applicationType, setApplicationType] = useState('');
    const [downloadStatus, setDownloadStatus] = useState('');
    const navigate=useNavigate();
    const handleChange = (event) => {
        setApplicationType(event.target.value);
        navigate('/bond-application-step-2');
    };

    const handleDownload = (event) => {
        alert("dowload will take place");
        setDownloadStatus(event.target.value);
    };

    return (
        <div className="bondApplication step1">
            <div className="topPage">
                <h1>Bond Application</h1>
                <h2>Step 1: Select Application Type</h2>
                <Box sx={{ maxWidth: 600, margin: '36px auto' }}>
                    <FormControl fullWidth>
                        <InputLabel id="online-bond-form">Application Type</InputLabel>
                        <Select
                            labelId="online-bond-form"
                            id="online-bond-form-select"
                            value={applicationType}
                            label="Select Application Type"
                            onChange={handleChange}
                            >
                            <MenuItem value="bond 1">Bond 1</MenuItem>
                            <MenuItem value="bond 2">Bond 2</MenuItem>
                            <MenuItem value="bond 3">Bond 3</MenuItem>
                            <MenuItem value="bond 4">Bond 4</MenuItem>
                        </Select>
                    </FormControl>
                    <Button variant="contained" fullWidth onClick={handleChange} style={{textTransform: 'uppercase', backgroundColor: '#e4ddc4', marginTop: '12px', color: '#8f876a'}}>Select</Button>
                </Box>
            </div>
            <div className="bottomPage">
                <Box sx={{ maxWidth: 600, margin: '36px auto' }}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Application Type</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={downloadStatus}
                            label="Select Application Type"
                            onChange={handleChange}
                            >
                            <MenuItem value="bond 1">Bond 1</MenuItem>
                            <MenuItem value="bond 2">Bond 2</MenuItem>
                            <MenuItem value="bond 3">Bond 3</MenuItem>
                            <MenuItem value="bond 4">Bond 4</MenuItem>
                        </Select>
                    </FormControl>
                    <Button variant="contained" fullWidth onClick={handleDownload} style={{textTransform: 'uppercase', backgroundColor: '#e4ddc4', marginTop: '12px', color: '#8f876a'}}>Download Form</Button>
                </Box>
            </div>
        </div>
    )
}
export default BondStep1;