import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import "./MultipleSelectCheckmarks.scss";
import { ProgressStepper } from './Progress-Steps';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      fontSize: '0.9rem !important'
    },
  },
};

const phase1 = [
  '1.1 Preparing A Substantive Report',
  '1.2 Settlement - Setting Mediation',
  '1.3 Schedule Trial - Based Depositions',
  '1.4 Works Toward Mediation',
  '1.5 Reviewing Document For Trial',
];

const phase2 = [
  '2.1 Witnesses Depositions',
  '2.2 Designations of Experts',
  '2.3 60-90 Day Report',
  '2.4 Summary Reports',
  '2.5 Deposition Motions',
  '2.6 Reports',
]

const phase3 = [
  '3.1 Witnesses Depositions',
  '3.2 Designations of Experts',
  '3.3 60-90 Day Report',
  '3.4 Summary Reports',
  '3.5 Deposition Motions',
  '3.6 Reports'
]

const final = [
  '3.1 Witnesses Depositions',
  '3.2 Designations of Experts',
  '3.3 60-90 Day Report',
  '3.4 Summary Reports',
  '3.5 Deposition Motions',
  '3.6 Reports'
]

export default function MultipleSelectCheckmarks() {
  const [phase1Task, setPhase1task] = React.useState(['Phase 1 - First 30 Days']);
  const [phase2Task, setPhase2task] = React.useState(['Phase 2 - Within 60-100 Days']);
  const [phase3Task, setPhase3task] = React.useState(['Phase 3 - Within 101-180 Days']);
  const [finalTask, setFinalTask] = React.useState(['Trial']);

  const [phase1Status, setPhase1Status] = React.useState('notCurrent');
  const [phase2Status, setPhase2Status] = React.useState('notCurrent');
  const [phase3Status, setPhase3Status] = React.useState('notCurrent');
  const [finalStatus, setFinalStatus] = React.useState('current');

  const handleChangePhase1 = (event) => {
    const {
      target: { value },
    } = event;
    setPhase1task(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleChangePhase2 = (event) => {
    const {
      target: { value },
    } = event;
    setPhase2task(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleChangePhase3 = (event) => {
    const {
      target: { value },
    } = event;
    setPhase3task(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleChangeFinal = (event) => {
    const {
      target: { value },
    } = event;
    setFinalTask(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  return (
    <div>
      <ProgressStepper/>
      <div className="phaseContainer">
        
        <FormControl sx={{ m: 1, width: '100%' }}>
          {/* <InputLabel id="demo-multiple-checkbox-label" htmlFor ="demo-multiple-checkbox" style={{color: '#fff'}}>Phase 1 - First 30 Days</InputLabel> */}
          <Select
            labelId="demo-multiple-checkbox-label"
            id="phase1-multiple-checkbox"
            multiple
            value={phase1Task}
            onChange={handleChangePhase1}
            renderValue={(selected) => 'Phase 1 - First 30 Days'}
            MenuProps={MenuProps}
            style={{backgroundColor: `${phase1Status == 'notCurrent' ? '#2854A3' : '#0CBD3E'}`, color: '#fff', fontSize: '16px'}}
          >
            {phase1.map((name) => (
              <MenuItem key={name} value={name}>
                <Checkbox checked={phase1Task.indexOf(name) > -1} />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ m: 1, width: '100%' }}>
          {/* <InputLabel id="demo-multiple-checkbox-label" htmlFor ="demo-multiple-checkbox" style={{color: '#fff'}}>Phase 1 - First 30 Days</InputLabel> */}
          <Select
            labelId="demo-multiple-checkbox-label"
            id="phase2-multiple-checkbox"
            multiple
            value={phase2Task}
            onChange={handleChangePhase2}
            renderValue={(selected) => 'Phase 2 - Within 60-100 Days'}
            MenuProps={MenuProps}
            style={{backgroundColor: `${phase2Status == 'notCurrent' ? '#2854A3' : '#0CBD3E'}`, color: '#fff', fontSize: '16px'}}
          >
            {phase2.map((name) => (
              <MenuItem key={name} value={name}>
                <Checkbox checked={phase2Task.indexOf(name) > -1} />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ m: 1, width: '100%' }}>
          {/* <InputLabel id="demo-multiple-checkbox-label" htmlFor ="demo-multiple-checkbox" style={{color: '#fff'}}>Phase 1 - First 30 Days</InputLabel> */}
          <Select
            labelId="demo-multiple-checkbox-label"
            id="phase3-multiple-checkbox"
            multiple
            value={phase3Task}
            onChange={handleChangePhase3}
            renderValue={(selected) => 'Phase 3 - Within 101-180 Days'}
            MenuProps={MenuProps}
            style={{backgroundColor: `${phase3Status == 'notCurrent' ? '#2854A3' : '#0CBD3E'}`, color: '#fff', fontSize: '16px'}}
          >
            {phase3.map((name) => (
              <MenuItem key={name} value={name} style={{backgroundColor: '#D9D9D9', borderBottom: 'solid 1px #rgb(155 155 155)'}}>
                <Checkbox checked={phase3Task.indexOf(name) > -1} />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, width: '100%' }}>
          {/* <InputLabel id="demo-multiple-checkbox-label" htmlFor ="demo-multiple-checkbox" style={{color: '#fff'}}>Phase 1 - First 30 Days</InputLabel> */}
          <Select
            labelId="demo-multiple-checkbox-label"
            id="final-multiple-checkbox"
            multiple
            value={finalTask}
            onChange={handleChangeFinal}
            renderValue={(selected) => 'Trial'}
            MenuProps={MenuProps}
            style={{backgroundColor: `${finalStatus == 'notCurrent' ? '#2854A3' : '#0CBD3E'}`, color: '#fff', fontSize: '16px'}}
          >
            {final.map((name) => (
              <MenuItem key={name} value={name} style={{backgroundColor: '#D9D9D9', borderBottom: 'solid 1px #rgb(155 155 155)'}}>
                <Checkbox checked={finalTask.indexOf(name) > -1} />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </div>
  );
}