import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardContent, Typography } from '@mui/material';

const FloorPlans = (props) => {
    const baseUrl = localStorage.getItem("baseUrl");
    const clientId = localStorage.getItem("clientId");
    console.log("baseUrl", baseUrl);
    const floorPlansAPI = props.floorPlansAPI;
    localStorage.setItem("floorPlansAPI", floorPlansAPI);
    console.log("floor plans API", floorPlansAPI);
    const [floorPlans, setFloorPlans] = useState(null);
    useEffect(()=> {
        fetch(floorPlansAPI)
        .then(result => result.json())
        .then(result => {
            setFloorPlans(result);
        })
    }, []);

    return (
        <div>
            <h3>Floor Plans</h3>
            <div style={{display:'flex', gap:'12px', justifyContent: 'start', flex: 1, flexWrap: 'wrap'}}>
                {floorPlans && floorPlans.map((plan) => (
                    <Card style={{minWidth: 240}}>
                        <Link to={`/details/${plan.floor_plan_id}`} key={plan.floor_plan_id}>
                            <img src={`${baseUrl}${plan.floor_plan_image}`} style={{maxWidth:240}} alt="Thumbnail" />
                        </Link>
                        <CardContent>
                        <Typography variant="h6">{plan.floor_plan_id}</Typography>
                        <Typography>Floors: {plan.floors}</Typography>
                        <Typography>Bathrooms: {plan.bathrooms}</Typography>
                        <Typography>Garage: {plan.garage}</Typography>
                        <Typography>Total Square Feet: {plan.total_square_feet}</Typography>
                        </CardContent>
                    </Card>
                ))}
            </div>
        </div>
    )
}
export default FloorPlans;