import { useState, useEffect } from 'react';

const useChannels = (endpoint) => {
    const [channels, setChannels] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchChannels = async () => {
            fetch(endpoint)
            .then(result=> result.json())
            .then(result=>result.channels)
            .then(result => {
                console.log("Channels", result);
                setChannels(result);
                setLoading(false);
            })
            .catch(err => {
                setError(err);
            });
        };

        fetchChannels();
    }, [endpoint]);

    return { channels, loading, error };
};

export default useChannels;