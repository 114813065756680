import { useState, useEffect } from 'react';

const useChannelCategories = (endpoint) => {
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchCategories = async () => {
            fetch(endpoint)
            .then(result=> result.json())
            // .then(result=>result.categories) //Use only for Lambda Function
            .then(result => {
                console.log("categories from PHP", result);
                setCategories(result);
                setLoading(false);
            })
            .catch(err => {
                setError(err);
            });
        };

        fetchCategories();
    }, [endpoint]);

    return { categories, loading, error };
};

export default useChannelCategories;