import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Container, Typography, Card, CardContent, CardActions, InputLabel, MenuItem, Select, FormControl } from '@mui/material';
import { Auth, Amplify, Storage } from 'aws-amplify';
import QRCode from "react-qr-code";
import { countries } from 'country-data';

Amplify.configure({
    Storage: {
      AWSS3: {
        bucket: 'underground-expressions-2022',
        region: 'us-east-1', // specify the correct region here
      },
    },
});

const EditProfile = () => {
  const [userAttributes, setUserAttributes] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [pictureFile, setPictureFile] = useState(null);
  const [vcf, setVcf] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState('');

  const filteredCountryData = Object.keys(countries).reduce((acc, countryCode) => {
    const country = countries[countryCode];
    if (country.countryCallingCodes && country.countryCallingCodes.length > 0) {
      acc[countryCode] = country;
    }
    return acc;
  }, {});

  const countryOptions = Object.keys(filteredCountryData).map((countryCode) => {
    const country = filteredCountryData[countryCode];
    return {
      value: `${country.countryCallingCodes[0]}`,
      label: `${country.name} (${country.countryCallingCodes[0]})`,
    };
  });
  // const countryOptions = [
  //   { value: '+1', label: 'United States (+1)' },
  //   { value: '+1', label: 'Canada (+1)' },
  //   // Add more countries as needed
  // ];
  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);

  };
  const attributeLabels = {
    'given_name': 'First Name',
    'family_name': 'Last Name',
    'custom:clients': 'Client IDs',
    'phone_number': 'Phone',
    'picture': 'Profile Image',
    'custom:qr_code_vcard': 'vCard QR Code',
    'custom:organization': 'Organization',
    'custom:job_title': 'Job Title',
    'website': 'Website',
    'custom:joomla_user_id': 'Joomla User ID'
  };

  const defaultAttributes = {
    'given_name': '',
    'family_name': '',
    'custom:clients': '',
    'phone_number': '',
    'picture': '',
    'custom:qr_code_vcard':'',
    'custom:organization': '',
    'custom:job_title': '',
    'website': '',
    'custom:joomla_user_id': '',
  };

  const createWebUser = async () => {
    const currentUser = await Auth.currentAuthenticatedUser();
    const payload = {
        // block: "0",
        email: userAttributes.email,
        groups: ["10"],
        id: 1000, //userAttributes.sub,
        // lastResetTime: "",
        // lastvisitDate: "",
        name: `${userAttributes.given_name} ${userAttributes.family_name}`,
        // params: {
        //     admin_language: "",
        //     admin_style: "",
        //     editor: "",
        //     helpsite: "",
        //     language: "",
        //     timezone: ""
        // },
        password: "qwertyqwerty123",
        password2: "qwertyqwerty123",
        // registerDate: "",
        // requireReset: "0",
        // resetCount: "0",
        // sendEmail: "0",
        username: `${userAttributes.sub}`
    };

    const response = await fetch(`https://foundmusicapp.com/${process.env.REACT_APP_BASE_PATH}/users`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${process.env.REACT_APP_JOOMLA4_API_KEY}`
        },
        body: JSON.stringify(payload)
    });
    
    const responseData = await response.json().then(resp=>{
      console.log("RESP", resp);
      Auth.updateUserAttributes(currentUser, { ...userAttributes, 'custom:joomla_user_id': resp.data.id,});
    })
    
    console.log("token", responseData);
    return responseData;
  }




  async function generateVcfContent(attributes) {
    let content = `BEGIN:VCARD
    VERSION:3.0
    FN:${attributes.given_name} ${attributes.family_name}`;

    if (attributes.email) {
        content += `\nEMAIL:${attributes.email}`;
    }

    if (attributes.phone_number) {
        content += `\nTEL:${attributes.phone_number}`;
    }

    if (attributes.organization && attributes.organization.trim() !== '') {
        content += `\nORG:${attributes.organization}`;
    }

    if (attributes.picture) {
        try {
            // Fetch the image and convert it to Base64
            const response = await fetch(attributes.picture);
            const blob = await response.blob();
            const base64Image = await blobToBase64(blob);

            // Add the PHOTO field to the vCard
            content += `\nPHOTO;MEDIATYPE=${blob.type};ENCODING=B;BASE64:${base64Image}`;
        } catch (err) {
            console.error('Failed to fetch and encode image:', err);
        }
    }

    content += "\nEND:VCARD";
    return content;
  };

  // const createWebUser = () => {
  //   console.log(userAttributes.sub, userAttributes.email);
  // }

  function blobToBase64(blob) {
      return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = function () {
              resolve(reader.result.split(',')[1]); // Get only the Base64 content
          };
          reader.onerror = reject;
          reader.readAsDataURL(blob);
      });
  }


  
  const navigate = useNavigate();
  

  useEffect(() => {
    const fetchAttributes = async () => {
      try {
        const currentUser = await Auth.currentAuthenticatedUser();
        const attributes = { ...currentUser.attributes };
        //delete attributes.email; // Exclude the email attribute
  
        // Merge the user attributes with the default ones
        const mergedAttributes = { ...defaultAttributes, ...attributes };
  
        setUserAttributes(mergedAttributes);
        
        setLoading(false);
      } catch (error) {
        console.error('Error fetching user attributes:', error);
      }
    };
  
    fetchAttributes();
  }, []);
  

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUserAttributes(name !== 'country' ? {
      ...userAttributes,
      [name]: value,
    } : null);
  };

  const handleFileChange = (event) => {
    setPictureFile(event.target.files[0]);
  };

  const handleCancel = () => {
    navigate('/');
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    try {
      let pictureUrl;
      let vcfUrl; // URL for the VCF file

      const updatedPhoneNumber = `${selectedCountry}${userAttributes.phone_number}`;
  
      if (pictureFile) {
        // Generate a unique file name with a timestamp
        const fileName = `profile-pictures/${Date.now()}-${pictureFile.name}`;
        const bucketName = 'underground-expressions-2022';
  
        // Upload the selected file to the specified S3 bucket
        await Storage.put(fileName, pictureFile, {
          contentType: pictureFile.type,
          bucket: 'underground-expressions-2022',
        });
  
        // Construct the URL of the uploaded file
        pictureUrl = `https://${bucketName}.s3.amazonaws.com/public/${encodeURIComponent(fileName)}`;
      }

      // Create and upload the VCF file if any user attribute changes
      const vcfContent = await generateVcfContent(userAttributes);
      const vcfBlob = new Blob([vcfContent], { type: "text/vcard" });
      const vcfFileName = `vcf-files/${Date.now()}-${userAttributes.given_name}.vcf`;

      await Storage.put(vcfFileName, vcfBlob, {
        contentType: 'text/vcard',
        bucket: 'underground-expressions-2022',
      });

      vcfUrl = `https://underground-expressions-2022.s3.amazonaws.com/public/${encodeURIComponent(vcfFileName)}`;
      const qrCodeLink = `<QRCode value="${vcfUrl}" />`;  // Not sure how you want to use this
  
      // Update the user's `picture` attribute with the URL of the uploaded file
      const currentUser = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(currentUser, { ...userAttributes, ...(pictureUrl && { picture: pictureUrl }), 'custom:qr_code_vcard': vcfUrl, phone_number: updatedPhoneNumber,});

      navigate('/qrcode');
    } catch (error) {
      console.error('Error updating profile:', error);
      alert('Error updating profile. Please try again.');
    }
};
  
  

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Container component="main" maxWidth="sm">
      <Card elevation={3} style={{ padding: '20px', marginTop: '20px', backgroundColor: '#fff' }}>
        <CardContent>
          <Typography component="h1" variant="h5">
            Edit Profile
          </Typography>
          <form onSubmit={handleSubmit}>
          {Object.keys(userAttributes)
            .filter((key) => key !== 'sub' && key !== 'custom:clients' && key !== 'email_verified' && key !== 'email' && key !== 'phone_number_verified' && key !== 'custom:user_role' && key !== 'custom:qr_code_vcard') // Exclude the "sub" field
            .map((key) => {
              if(key === 'phone_number') {
                return (
                  <div key={key}>
                      <FormControl fullWidth>
                        <InputLabel htmlFor="country">Country Code</InputLabel>
                        <Select
                          id="country"
                          name="country"
                          value={selectedCountry}
                          onChange={handleCountryChange}
                          label="Country Code"
                        >
                          {countryOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required={false}
                        fullWidth
                        id={key}
                        label={attributeLabels[key]}
                        name={key}
                        value={userAttributes[key]}
                        onChange={handleInputChange}
                        InputProps={{
                          readOnly: key === 'picture',
                        }}
                      />
                  </div>
                )
              } else if (key === 'custom:joomla_user_id') {
                return (
                  <TextField
                    key={key}
                    variant="outlined"
                    margin="normal"
                    required={false}
                    fullWidth
                    id={key}
                    label={attributeLabels[key]}
                    name={key}
                    value={userAttributes[key]}
                    onChange={handleInputChange}
                    InputProps={{
                      readOnly: key === 'custom:joomla_user_id'
                    }}
                  />
                )
              } else {
                return (
                  <TextField
                    key={key}
                    variant="outlined"
                    margin="normal"
                    required={false}
                    fullWidth
                    id={key}
                    label={attributeLabels[key]} // You can use a mapping object here if you want to change the labels
                    name={key}
                    value={userAttributes[key]}
                    onChange={handleInputChange}
                    InputProps={{
                      readOnly: key === 'picture',
                    }}
                  />
                )
              }
                
            })}
            <input
              type="file"
              accept="image/*"
              name="file"
              onChange={handleFileChange}
              style={{ display: 'block', marginTop: '10px' }}
            />
            <Button onClick={createWebUser}>Create Web User</Button>
            <Button type="submit" fullWidth variant="contained" color="primary" style={{ marginTop: '20px' }}>
              Update Info
            </Button>
            <CardActions>
              <Button size="small" onClick={handleCancel}>Cancel</Button>
            </CardActions>
          </form>
        </CardContent>
      </Card>
    </Container>
  );
};

export default EditProfile;
