import React, { useState, useContext, useEffect } from 'react';
import { TextField, Button, Select, MenuItem, InputLabel, FormControl, Checkbox, FormControlLabel, Radio, RadioGroup, Chip } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ThemeContext } from '../../App';
import { API } from 'aws-amplify';
import useChannelCategories from '../../../hooks/useChannelCategories';
import AWS from 'aws-sdk';
import usePrograms from '../../../hooks/usePrograms';

// Configure AWS SDK (e.g., set your region and credentials)
AWS.config.update({
    region: process.env.REACT_APP_REGION, // for example, 'us-west-2'
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY
});

const ChannelBuilder = (props)=> {
    const clientId = localStorage.getItem("clientID");
    const baseUrl = localStorage.getItem("baseUrl");
    const apiEndpointCategories = props.categoryAPI; 
    const apiEndpointChannelBuilder = `${process.env.REACT_APP_CHANNEL_BUILDER_ENDPOINT}?network=${clientId}`;
    const apiEndpointPrograms = `${process.env.REACT_APP_PROGRAM_LIST_ENDPOINT}?network=${clientId}`;
    const { categories, loading, error } = useChannelCategories(apiEndpointCategories);
    const { programs } = usePrograms(apiEndpointPrograms);
    
    const [categoryList, setCategoryList] = useState(null);
    const [programList, setProgramList] = useState(null);
    // console.log("categories in builder", categories);
    const theme = useContext(ThemeContext);
    const s3 = new AWS.S3();
    const categoryDetails = useState(props.categoryDetails);
    const categoryTitle = categoryDetails.title;

    const uploadToS3 = (file) => {
        return new Promise((resolve, reject) => {
            const params = {
                Bucket: process.env.REACT_APP_BUCKET_NAME,
                Key: `${Date.now()}-${file.name}`, // Prefixing with Date.now() to avoid overwriting existing files
                Body: file,
                ContentType: file.type, // Set the appropriate content type for your file
                ACL: 'public-read' // If you want the file to be publicly accessible; remove or adjust as necessary
            };

            s3.upload(params, (err, data) => {
                if (err) {
                    console.error('Error uploading file:', err);
                    reject(err);
                } else {
                    console.log('Successfully uploaded file:', data.Location);
                    resolve(data.Key); // You can also resolve with data.Location if you want the full URL
                }
            });
        });
    };

    const [formData, setFormData] = useState({
        title: {value: categoryDetails.title, type: 'TextField'},
        state: { value: '', type: 'Select', options: ['1', '0']},
        programs: { value: [], type: 'Select', options: []},
        rating: {value: '', type: 'Select', options: ['General Audiences', 'Adult', 'Mature', 'PG-13']},
        categories: {value: [], type: 'Select', options: []},
        channel_type: { value: '', type: 'Select', options: ['Continuous','On-demand', 'Interactive', 'E-learning', 'Media Tracks']},
        alias: {value: '', type: 'TextField'},
        channel_image: { value: '', type: 'File'},
        channel_description: {value: '', type: 'TextArea'},
        elearning_channel: {value: false, type: 'Checkbox'},
        subscription_required: {value: false, type: 'Checkbox'},
        randomize: {value: false, type: 'Checkbox'},
        show_title_on_channel_tile: {value: false, type: 'Checkbox'},
        price: {value: '', type: 'TextField'},
        payment_frequency: {value: '', type: 'Select', options: ['Monthly', 'Yearly']},
        ordering: {value: 0, type: 'Number'},
    });
    useEffect(()=>{
        setCategoryList(categories);
        console.log("form data", categoryList);
    },[categories]);

    useEffect(() => {
        setProgramList(programs);
        console.log("programs in builder", programs);
    },[programs]);
    
    const formInput = {
        title: formData.title.value,
        state: formData.state.value,
        // programs: formData.programs.value.join(","),
        rating: formData.rating.value,
        // categories: formData.categories.value.join(","),
        channel_type: formData.channel_type.value,
        alias: formData.alias.value,
        channel_image: formData.channel_image.value,
        channel_description: formData.channel_description.value,
        elearning_channel: formData.elearning_channel.value,
        subscription_required: formData.subscription_required.value,
        randomize: formData.randomize.value,
        show_channel_title_on_tile: formData.show_title_on_channel_tile.value,
        price: formData.price.value,
        payment_frequency: formData.payment_frequency.value,
        base_url: baseUrl,
        ordering: formData.ordering.value,
        categories: formData.categories.value,
        network: clientId,
        channel_owner: props.userId,
    }
    const handleSubmit = async (e) => {
        // console.log("form input", formInput);
        e.preventDefault();
        try {
            const response = await fetch(apiEndpointChannelBuilder,{
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formInput),
            });
            const responseData = await response.json();
            console.log('Success2:', responseData);
            setFormData({
                title: {value: categoryDetails.title, type: 'TextField'},
                state: { value: '', type: 'Select', options: ['1', '0']},
                programs: { value: [], type: 'Select', options: []},
                rating: {value: '', type: 'Select', options: ['General Audiences', 'Adult', 'Mature', 'PG-13']},
                categories: {value: [], type: 'Select', options: []},
                channel_type: { value: '', type: 'Select', options: ['Continuous','On-demand', 'Interactive', 'E-learning', 'Media Tracks']},
                alias: {value: '', type: 'TextField'},
                channel_image: { value: '', type: 'File'},
                channel_description: {value: '', type: 'TextArea'},
                elearning_channel: {value: false, type: 'Checkbox'},
                subscription_required: {value: false, type: 'Checkbox'},
                randomize: {value: '', type: 'Checkbox'},
                show_title_on_channel_tile: {value: false, type: 'Checkbox'},
                price: {value: '', type: 'TextField'},
                payment_frequency: {value: '', type: 'Select', options: ['Monthly', 'Yearly']},
                ordering: {value: 0, type: 'Number'},
            });
        } catch (error) {
            console.error('Error saving form data', error);
        };
        
    };
    

    

    const handleChange = (e) => {
        const { name, value } = e.target;
        const isMultiple = e.target.multiple; // Check if it's a multiple select
    
        let updatedValue;
    
        if (isMultiple) {
            // If it's a multiple select, ensure value is an array
            updatedValue = Array.from(e.target.selectedOptions).map(option => option.value);
        } else {
            updatedValue = value;
        }
        console.log("category array", updatedValue);
    
        setFormData(prevData => ({
            ...prevData,
            [name]: { ...prevData[name], value: updatedValue }
        }));
    };
    

    const handleFileChange = async (event) => {
        console.log("file", event);
        const { name, files } = event.target;
        if (files.length) {
            // Step 1: Upload to S3
            const file = files[0];
            try {
                const s3Key = await uploadToS3(file); // Correctly call the function and use await to get the promise result
                
                // Step 2: Store the S3 Key in your form data (or save to DynamoDB)
                setFormData(prevData => ({
                    ...prevData,
                    [name]: { ...prevData[name], value: s3Key }
                }));
            } catch (error) {
                console.error("Error uploading the file:", error);
            }
        }
    };
    useEffect(() => {
        console.log("updated form data", formData);
    }, [formData]);
    
    

    return (
        <>
            <h4>Channel Builder</h4>
            <div style={{display: 'flex', flexDirection: 'column', backgroundColor: '#fff', padding: '6px', marginTop: '6px', color: 'rgba(0, 0, 0, 0.87)'}}>
                <form onSubmit={handleSubmit} style={{display: 'flex', flexDirection: 'column'}}>
                    {Object.entries(formData).map(([key, field]) => {
                        const label = key.replace(/_/g, ' ').charAt(0).toUpperCase() + key.slice(1);
                        switch (field.type) {
                            case 'TextField':
                                return (
                                    <TextField
                                        key={key}
                                        name={key}
                                        label={label}
                                        value={field.value}
                                        onChange={handleChange}
                                        fullWidth
                                        margin="dense"
                                    />
                                );
                            case 'Number':
                                return (
                                    <TextField
                                        key={key}
                                        name={key}
                                        label={label}
                                        value={field.value}
                                        onChange={handleChange}
                                        fullWidth
                                        margin="dense"
                                        type="number"
                                    />
                                );
                            case 'Select':
                                if (key === "categories") {
                                    return (
                                        <FormControl key={key} fullWidth margin="none">
                                            <InputLabel id={`${key}-label`}>{label}</InputLabel>
                                            <Select
                                                labelId={`${key}-label`}
                                                name={key}
                                                value={field.value}
                                                onChange={handleChange}
                                                multiple
                                            >
                                                {categories.map(option => (
                                                    <MenuItem key={option.id} value={option.id}>
                                                        {option.title}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    );
                                } else if(key === "programs") {
                                    return (
                                        <FormControl key={key} fullWidth margin="none">
                                            <InputLabel id={`${key}-label`}>{label}</InputLabel>
                                            <Select
                                                labelId={`${key}-label`}
                                                name={key}
                                                value={field.value}
                                                onChange={handleChange}
                                                multiple
                                            >
                                                {programs.map(option => (
                                                    <MenuItem key={option.id} value={option.id}>
                                                        {option.title}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    );
                                } else {
                                    return (
                                        <FormControl key={key} fullWidth margin="none">
                                            <InputLabel id={`${key}-label`}>{label}</InputLabel>
                                            <Select
                                                labelId={`${key}-label`}
                                                name={key}
                                                value={field.value}
                                                onChange={handleChange}
                                            >
                                                {field.options.map(option => (
                                                    <MenuItem key={option} value={option}>
                                                        {option}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    );
                                }
                            case 'Checkbox':
                                return (
                                    <FormControlLabel
                                        key={key}
                                        control={
                                            <Checkbox
                                                name={key}
                                                checked={field.value}
                                                onChange={(e) => handleChange({ target: { name: key, value: e.target.checked } })}
                                            />
                                        }
                                        label={label}
                                    />
                                );
                            case 'File':
                                return (
                                    <label>
                                    Upload {label}
                                    <input
                                        type="file"
                                        name={key}
                                        onChange={handleFileChange}
                                    />
                                    </label>
                                );
                            case 'TextArea':
                                return (
                                    <TextField
                                        key={key}
                                        name={key}
                                        label={label}
                                        value={field.value}
                                        onChange={handleChange}
                                        fullWidth
                                        margin="dense"
                                        multiline
                                        minRows={4}
                                    />
                                );
                            case 'Tags':
                                return (
                                    <Autocomplete
                                        multiple
                                        key={key}
                                        id={`${key}-tags-filled`}
                                        options={field.options ? field.options.map((option) => option.title):''}
                                        freeSolo
                                        value={field.value}
                                        onChange={(event, newValue) => {
                                            setFormData(prevData => ({
                                                ...prevData,
                                                [key]: { ...prevData[key], value: newValue }
                                            }));
                                        }}
                                        renderTags={(value, getTagProps) =>
                                            value.map((option, index) => (
                                                <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                            ))
                                        }
                                        renderInput={(params) => (
                                            <TextField {...params} variant="filled" label={label} placeholder="Add a tag" fullWidth margin="dense" />
                                        )}
                                    />
                                );
                            default:
                                return null;
                        }
                    })}
                    <Button type="submit" variant="contained" color="primary">
                        Submit
                    </Button>
                </form>
            </div>
        </>
    );
}

export default ChannelBuilder;
