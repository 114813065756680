import { Amplify } from 'aws-amplify';

import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import awsExports from '../../aws-exports';
import { useContext, useEffect } from 'react';
import { AuthContextAWS } from '../../contexts/AuthContextAWS';
Amplify.configure(awsExports);

export default function App(props) {
const clientId = props.clientId;
const currentUser = useContext(AuthContextAWS);
  // useEffect(() => {
  //   if(currentUser) {
  //     // console.log("current user", currentUser);
  //     const attributes = currentUser.attributes;
  //     const getUserAttributes = async () => {
  //       try {
  //       //   const currentUser = await Auth.currentAuthenticatedUser();
          
  //         console.log("user attributes on sign in", attributes);
  //       } catch (error) {
  //         console.error('Error fetching user attributes:', error);
  //       }
  //   };
  
  //   getUserAttributes();
  // }
  
// }, [])
  const signUpFields = {
    signUp: {
      'custom:clients': {
        type: 'clients',
        placeholder: 'Enter the client ID',
        label: 'Network ID',
        value: clientId,
        readOnly: true,
      },
      'given_name': {
        type: 'text',
        placeholder: 'First Name',
        label: 'First Name',
      },
      'family_name': {
        type: 'text',
        placeholder: 'Last Name',
        label: 'Last Name',
      }
    }
  }
  return (
    <Authenticator slot="sign-up"
    usernameAlias="email" formFields={signUpFields}>
      {({ signOut, user }) => (
        <main>
          <h1>Hello {user.attributes.given_name}</h1>
          <button onClick={signOut}>Sign out</button>
        </main>
      )}
    </Authenticator>
  );
}