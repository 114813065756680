import React, {useEffect, useState} from "react";

const CreatorProfile = () => {
    const baseUrl = localStorage.getItem("baseUrl");
    const api_key = process.env.REACT_APP_JOOMLA4_API_KEY;
    const [profileData, setProfileData] = useState(null);
    const [avatar, setAvatar] = useState(null);
    console.log("baseUrl", baseUrl);
    console.log("Joomla key", api_key);
    useEffect(()=>{
        fetch(`${baseUrl}${process.env.REACT_APP_BASE_PATH}/users/547`, {
            method: 'GET',
            headers: {
                'X-Joomla-Token': `${api_key}`,
                'Authorization': `Bearer ${api_key}`
            }
        })
        .then(response => response.json())
        .then(data => {
            console.log('user response', data.data);
            setProfileData(data.data);
            // Given JSON string
            const jsonString = data.data.attributes.avatar;

            // Parse the JSON string
            const jsonData = JSON.parse(jsonString);
            console.log("jsonData", jsonData);

            // Extract the image file path
            const imagePath = jsonData.imagefile.split('#');
            console.log("imagePath", imagePath[0]);

            // Replace backslashes with forward slashes
            // const cleanImagePath = imagePath.replace(/\\/g, '/');
            setAvatar(imagePath[0]);
            console.log("avatar", avatar);

            // Log the clean URL
            

            
        })
    },[avatar])

    return (
        <div className="container">
            <h2>Profile</h2>
            {<img src={`${baseUrl}${avatar}`} style={{maxWidth: '240px'}}/>}
        </div>
        
    )
}
export default CreatorProfile;