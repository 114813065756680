import React from 'react';
import './dashboardCard.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCaretDown,
  faCaretUp,
  faChartLine,
  faEllipsisV,
} from '@fortawesome/free-solid-svg-icons';



export const DashboardCard = ({ ...props }, {...cardDetails}) => {
  console.log("daily card", props.cardDetails.header);
  const upDown = (time, direction) => {
    if (time === 'day') {
      if (direction === 'up') return faCaretUp;
      if (direction === 'down') return faCaretDown;
    }
    if (time === 'ytd') return faChartLine;
  };
  
  const dayVyear = (time) => {
    if (time === 'day') return 'Today';
  };

  return (
    <>
      <div className='storybook-dashboardCard'>
        <div className='storybook-dashboardCard--Header'>
          <span>{props.cardDetails.header}</span>
          <FontAwesomeIcon icon={faEllipsisV} />
        </div>
        <div
          className={[
            'storybook-dashboardCard--Amount',
            `storybook-dashboardCard--Amount--${props.cardDetails.type}`,
          ].join(' ')}
        >
          {props.cardDetails.amount}
        </div>
        <div
          className={[
            'storybook-dashboardCard--SubText',
            `storybook-dashboardCard--SubText--${props.cardDetails.type}`,
          ].join(' ')}
        >
          <span
            className={[
              'storybook-dashboardCard--Direction',
              `storybook-dashboardCard--Direction--${props.cardDetails.direction}`,
              `storybook-dashboardCard--Direction--${props.cardDetails.time}`,
            ].join(' ')}
          >
            <FontAwesomeIcon icon={upDown(props.cardDetails.time, props.cardDetails.direction)} />
          </span>
          <span className='storybook-dashboardCard--Percent'>
            {props.cardDetails.percent}%
          </span>
          <span className='storybook-dashboardCard--Time'>
            {dayVyear(props.cardDetails.time)}
          </span>
        </div>
      </div>
    </>
  );
};
