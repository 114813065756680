import React, { useContext, useState, useEffect } from "react";
import { ThemeContext } from "../../App";
import "./MatterDetail.scss";
import AddBoxIcon from '@mui/icons-material/AddBox';
import MultipleSelectCheckmarks from "../../web-components/MultipleSelectCheckmarks";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const MatterDetail = () => {
    const theme = useContext(ThemeContext);
    const themeMui = useTheme();
    const [expanded, setExpanded] = React.useState('');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
    // const isMediumOrLarger = useMediaQuery(themeMui.breakpoints.up('md'));
    // const [expanded, setExpanded] = useState(false);
    // // Update the expanded state based on screen size
    // useEffect(() => {
    //     setExpanded(isMediumOrLarger);
    // }, [isMediumOrLarger]);

    // // Handle manual expansion and collapsing
    // const handleChange = () => {
    //     if (expanded == true) {
    //         setExpanded(false);
    //     } else {
    //         setExpanded(true);
    //     }
    // };
    return (
        <div className="legal" style={{backgroundImage: `url (${theme[0].page_bagkround_image})`}}>
            <div className="matterDetailContainer">
                <div className="matterDetailHeader">
                    <h1 className="pageHeader">Case Details</h1>
                </div>
                <div className="pageContainer">
                    <div className="columnLeft">
                        <div className="topDetails">
                            <ul>
                                <li>Open Date: 01/01/2023</li>
                                <li>Claim #: 678901</li>
                                <li>File #: 142.0269</li>
                                <li>Client: Aspen Insurance</li>
                            </ul>
                        </div>
                        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>Attorneys</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className="dataBox">
                                    <ul>
                                        <li>First Chair: John Smith</li>
                                        <li>Second Chair: Mary Doe</li>
                                        <li>Third Chair: Charles Kirkland</li>
                                    </ul>
                                </div>                                  
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                            <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                            >
                                <Typography>Collaborators</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className="dataBox">
                                    <ul>
                                        <li>Erich Schenk</li>
                                        <li>Jaclyn Graeber</li>
                                        <li>Karen Moseley</li>
                                        <li>RJ Blue</li>
                                        <li>Suni Madden</li>
                                    </ul>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                            <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel3a-content"
                            id="panel3a-header"
                            >
                            <Typography>Adjuster</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className="dataBox">
                                    <ul>
                                        <li>Adjuster contact info HERE</li>
                                    </ul>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                            <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4a-content"
                            id="panel4a-header"
                            >
                            <Typography>Paralegals</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className="dataBox">
                                    <ul>
                                        <li>Erich Schenk</li>
                                        <li>Jaclyn Graeber</li>
                                    </ul>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                            <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel5a-content"
                            id="panel5a-header"
                            >
                            <Typography>Case Notes</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className="dataBox">
                                    <div className="dataInterior">
                                        <div className="notesBoxHeader">
                                            <span className="noteTaker">Mary Doe</span><span className="noteDate">02/10/2023</span>
                                        </div>
                                        <span className="noteLabel">Sed do eiusmod</span>
                                        <div>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> 
                                        </div>
                                    </div>
                                    <div className="dataInterior">
                                        <div className="notesBoxHeader">
                                            <span className="noteTaker">Mary Doe</span><span className="noteDate">02/10/2023</span>
                                        </div>
                                        <span className="noteLabel">Sed do eiusmod</span>
                                        <div>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> 
                                        </div>
                                    </div>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                        {/* <div className="dataBox">
                            <ul>
                                <li className="listHeader">Attorneys</li>
                                <li>First Chair: John Smith</li>
                                <li>Second Chair: Mary Doe</li>
                                <li>Third Chair: Charles Kirkland</li>
                            </ul>
                        </div>
                        <div className="dataBox">
                            <ul>
                                <li className="listHeader">Collaborators</li>
                                <li>Erich Schenk</li>
                                <li>Jaclyn Graeber</li>
                                <li>Karen Moseley</li>
                                <li>RJ Blue</li>
                                <li>Suni Madden</li>
                            </ul>
                        </div>
                        <div className="dataBox">
                            <ul>
                                <li className="listHeader">Adjuster</li>
                                <li>Adjuster contact info HERE</li>
                            </ul>
                        </div>
                        <div className="dataBox">
                            <ul>
                                <li className="listHeader">Paralegals</li>
                                <li>Erich Schenk</li>
                                <li>Jaclyn Graeber</li>
                            </ul>
                        </div>
                        <div className="dataBox">
                            <div className="titleBox">
                                <span className="dataBoxTitle">Case Notes</span> <AddBoxIcon className="icon" />
                            </div>
                            <div className="dataInterior">
                                <div className="notesBoxHeader">
                                    <span className="noteTaker">Mary Doe</span><span className="noteDate">02/10/2023</span>
                                </div>
                                <span className="noteLabel">Sed do eiusmod</span>
                                <div>
                                   <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> 
                                </div>
                            </div>
                            <div className="dataInterior">
                                <div className="notesBoxHeader">
                                    <span className="noteTaker">Mary Doe</span><span className="noteDate">02/10/2023</span>
                                </div>
                                <span className="noteLabel">Sed do eiusmod</span>
                                <div>
                                   <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> 
                                </div>
                            </div>
                        </div> */}
                        <div className="viewButton">View All Case Notes</div>
                        <div className="viewButton">View Phase Work Log</div>
                    </div>
                    <div className="column-right">
                        <MultipleSelectCheckmarks/>
                        <h6>Matter Style: Rita Arredondo v. Hobby Lobby Stores, Inc., Pasadena Partners, L.P., and Balogh Family Holdings, LTD</h6>
                        <div className="workLog">
                            <div className="worklogHeader">
                                <span className="listHeader">Phase 3.1 Witness Depositions</span> 
                                <span className="listHeader" htmlFor="completed">Mark Completed</span>
                                <input id="compleded" type="checkbox" style={{width: 'auto'}}/>
                            </div>
                            
                            {/* <div className="totalHours">
                                <span className="listHeader" htmlFor="totalHours" style={{minWidth: '120px'}}>Total Hours</span>
                                <input type="text" id="totalHours" value="5.25" style={{border: '1px solid rgb(141 141 141)', borderRadius: '3px', maxWidth: '80px'}}/>
                            </div> */}
                        </div>
                        <div style={{maxWidth: '97%'}}>
                            <p>
                                On the 101st day we should be preparing a substantive report to be utilized to work the client through a very important decision.  The decision will be whether to try the case or to settle the case.  This invitation to discuss the case will include adjusters, supervisors, and anyone else that is a part of the fight or flight team associated with this case.  This should be your opportunity to make a presentation to all of the decision-makers at one time in a take charge manner that provides them with all of the information they need to know.  At the end of the meeting, you will want to know whether the client wants to try the case or settle the case.  Whatever the decision the client comes up with after this date, every bit of your energy and time will go towards getting the case either settled or tried.
                            </p>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    )
}

export default MatterDetail;