import React, { useContext, useEffect } from "react";
import { DirectoryCard } from "../web-components/DirectoryCard";
import { ThemeContext } from "../App";
import "./Directory.scss"

const Directory = (props) => {
    const theme = useContext(ThemeContext);
    useEffect(()=>{
        console.log("theme", theme[0].page_bagkround_image);
    },[theme])
    
    // mock data
    const Avatar = "https://thewillislawgroup.app/images/avatar.png?1703077165000";
  const directory={
    type: {
        directoryTitle: "Surety Directory",
        type: "surety",
    },
    args: {
        title: "Card Header",
        content: "Card Content",
        footer: "Card Footer",
        headerBackgroundColor: '#B0AA92',
        headerFontColor: '#fff',
        suretyBackgroundColor: '#596ea1',
        attorneyBackgroundColor: '#e18519',
        clientBackgroundColor: '#b13216',
        contentBackgroundColor: '#596ea1',
        contentFontColor: '#fff',
        padding: '12px',
        minWidth: '240px',
        footerBackgroundColor: '#fff',
        footerFontColor: '#000',
        border: 'none',
        borderRadius: '7px',
        cardHeader: false,
        cardContent: true,
        avatar: Avatar,
        firstName: "Jared"
    },
    members: [
        {
            contentBackgroundColor: '#596ea1',
            firstName: 'Jared',
            lastName: 'Holt',
            avatar: Avatar,
        },
    ]
  }

    return (
        <div class="directoryContainer">
            <h1 className="pageHeader">{props.title}</h1>
            <div className="directoryCards">
                <DirectoryCard directory={directory} type={props.type}/>
                <DirectoryCard directory={directory} type={props.type} />
                <DirectoryCard directory={directory} type={props.type} />
                <DirectoryCard directory={directory} type={props.type} />
                <DirectoryCard directory={directory} type={props.type} />
                <DirectoryCard directory={directory} type={props.type} />
                <DirectoryCard directory={directory} type={props.type} />
                <DirectoryCard directory={directory} type={props.type} />
                <DirectoryCard directory={directory} type={props.type} />
                <DirectoryCard directory={directory} type={props.type} />
                <DirectoryCard directory={directory} type={props.type} />
                <DirectoryCard directory={directory} type={props.type} />
            </div>
        </div>
    )
}
export default Directory;