import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import firebase from '../../../firebase';
import '@firebase/storage';
import DragNDrop from '../DragNDrop';

export default function UploadFormFB() {
  const [files, setFiles] = useState([]);

  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
  };

  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  }

  const storage = firebase.storage();

  const onDrop = useCallback((acceptedFiles) => {
    setFiles(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleUpload = async () => {
    const uploadPromises = files.map(file => {
      let uploadTask = storage.ref(`/files/${file.name}`).put(file);

      return uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, snapshot => {
        let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
      }, error => {
        console.log(error);
      }, () => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          console.log('File available at', downloadURL);
        });
      });
    });

    await Promise.all(uploadPromises);
    setFiles([]);
  };

  const dropzoneStyle = {
    height: '200px',
    width: '100%',
    borderWidth: '2px',
    borderColor: '#ccc',
    borderStyle: 'dashed',
    borderRadius: '7px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  };

  return (
    <div className='upload-steps'>
      <h4>File Upload</h4>
      <div {...getRootProps()} style={dropzoneStyle}>
        <input {...getInputProps()} />
        {
          isDragActive ?
            <p>Drop the files here ...</p> :
            <p>Drag 'n' drop some files here, or click to select files</p>
        }
      </div>
      {files.length > 0 &&
        <div>
          <h4>Files to be uploaded:</h4>
          <ul>
            {files.map((file, index) => <li key={index}>{file.name}</li>)}
          </ul>
        </div>
      }
      <button type="button" onClick={handleUpload} disabled={files.length === 0} style={{marginTop: '12px'}}>Upload</button>
      <DragNDrop />
    </div>
  );
}
