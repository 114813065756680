import React from 'react';

const MembershipshipDetails = () => {

    return (
        <>
            <h1>Membership Details</h1>
        </>
    )
}

export default MembershipshipDetails;