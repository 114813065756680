import React, { useState, useEffect, useContext } from 'react';
import { Auth } from 'aws-amplify';
import QRCode from "react-qr-code";
import { ThemeContext } from '../App';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, CardMedia, Typography, CardActions, Button, Link as MuiLink, ListItem, List } from '@mui/material';

const MyQRCode = () => {
    const [userAttributes, setUserAttributes] = useState(null);
    const [vcfUrl, setVcfUrl] = useState(null);
    const theme = useContext(ThemeContext);
    const navigate = useNavigate();
    useEffect(() => {
        const getUserAttributes = async () => {
          try {
            const currentUser = await Auth.currentAuthenticatedUser();
            const attributes = currentUser.attributes; // Get the user attributes
            setUserAttributes(attributes);
            setVcfUrl(attributes['custom:qr_code_vcard']);
          } catch (error) {
            console.error('Error fetching user attributes:', error);
          }
        };

        getUserAttributes();
    }, []);

    const handleEditProfile = function() {
        navigate('/edit-profile');
    }

    if (!userAttributes) {
        return <div>Loading...</div>;
    }
    return (
        <div>
            {vcfUrl && (
                <div>
                        <Card xs={400} style={{ backgroundColor: theme[0].page_background_color, color: theme[0].page_font_color, maxWidth: '320px', boxShadow: '6px 6px 12px #000' }}>
                    
                        <CardContent>
                            <Typography variant="h5">Scan QR code for vCard</Typography>
                        <QRCode value={vcfUrl} />
                        <Typography variant="h5">{userAttributes['given_name'] + ' ' + userAttributes['family_name']}</Typography>
                        {/* <Link component={MuiLink} style={{ color: theme[0].page_font_color, textDecoration: 'underline' }} to="/edit-profile">
                            Edit Profile
                        </Link> */}
                        </CardContent>
                        <CardMedia
                        component="img"
                        image={userAttributes['picture']}
                        alt={userAttributes['given_name']}
                        />
                        <List>
                            {userAttributes['phone_number'] && <ListItem>
                                <Typography variant="body">Phone: {userAttributes['phone_number']}</Typography>
                            </ListItem>}
                            {userAttributes['email'] && <ListItem>
                                <Typography variant="body">Email: {userAttributes['email']}</Typography>
                            </ListItem>}
                        </List>
                        <CardActions>
                            <Button size="small" style={{color: theme[0].page_font_color}} onClick={handleEditProfile}><FontAwesomeIcon icon="fa-solid fa-pen" /> &nbsp;Edit</Button>
                        </CardActions>
                    </Card>
              </div>
            )}

        </div>
    );
}

export default MyQRCode;