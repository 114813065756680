import React from 'react';
import InfoIcon from '@mui/icons-material/Info';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Bookmark from 'react-bookmark';
import { Link } from 'react-router-dom';
import { ThemeContext } from '../App';
import './modal.scss'

function ChannelDetailsModalContent(props) {
    const channelID = props.channelId;
    const [theme, setTheme] = React.useContext(ThemeContext);
    const [channelAlias, setChannelAlias] = React.useState(props.channelAlias);
      

    return (
        <React.Fragment>
            <ThemeContext.Provider value={theme}>
                <div className="modalDetailsBtn addToClipboard" onClick={props.handleOpen}><span style={{color: '#74308c'}}>View Details  <InfoIcon /></span></div>
                <Modal
                    open={props.open}
                    onClose={props.handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    style={{backgroundColor: 'rgba(0,0,0,.6)', border: '2px solid #fff'}}
                >
                    <Box sx={props.style} style={{backgroundColor: 'rgba(0,0,0,.6)', border: '1px solid #fff'}}>
                        <Typography style={{background: 'none', color: '#fff'}} id="modal-modal-title" variant="h6" component="h2">
                            {props.title}
                        </Typography>
                        <Typography style={{background: 'none', color: '#fff'}} body2="true" component="body" dangerouslySetInnerHTML={{__html: props.description}}/>
                    </Box>
                </Modal>
            </ThemeContext.Provider>
        </React.Fragment>
    )
}
export default ChannelDetailsModalContent