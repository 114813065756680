import React, { useEffect, createContext } from "react";
import { Helmet } from 'react-helmet';
import { Container } from "react-bootstrap";
import {BrowserRouter as Router, Routes, Route, useParams} from "react-router-dom";
import Profile from "../components/authentication/Profile";
import Login from "./authentication/Login";
import PrivateRoute from './authentication/PrivateRoute';
import ForgotPassword from "./authentication/ForgotPassword";
import UpdateProfile from "./Profile/UpdateProfile";
import Dashboard from "./Dashboard/Dashboard";
import Broadcast from './broadcast/Broadcast';
import '../styles/global.scss';
import Banners from "./banners/Banners";
import DenseAppBar from "./topHeaderBar/Appbar";
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas, faFontAwesome } from '@fortawesome/free-solid-svg-icons'
import UploadForm from "./admin/storage/UploadForm";
import UploadFormFB from "./admin/storage/UploadFormFB";
import MediaStore from "./media_store/MediaStore";
import Payments from "./stripe/Payments";
import Prices from "./stripe/subscription-flow/Prices";
import Subscribe from "./stripe/subscription-flow/Subscribe";
import Account from "./stripe/subscription-flow/Account";
import Cancel from "./stripe/subscription-flow/Cancel";
import Register from "./stripe/subscription-flow/Register";
import PaymentsDashboard from "./stripe/dashboard/PaymentsDashboard";
import { CheckOut } from "../stories/payments/PaymentPortal.stories";
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import AdminDashboard from "./admin/AdminDashboard";
import { Buffer } from 'buffer';
import AddContacts from "./contacts/AddContact";
import MyQRCode from "./contacts/QRCode";
import AuthForm from "./authentication/cognito/SignUp";
import MembershipTiers from "./memberships/MembershipTiers";
import MembershipshipDetails from "./memberships/MembershipDetails";
import SignIn from "./authentication/cognito/SignIn";
import { AuthProviderAWS } from "../contexts/AuthContextAWS";
import EditProfile from "./admin/EditeProfile";
import useChannelCategories from "../hooks/useChannelCategories";
import useChannels from "../hooks/useChannels";
import MediaCenter from "./broadcast/media-center/MediaCenter";
import FloorPlans from "./specialized/FloorPlans";
import FloorPlanDetails from "./specialized/FloorPlanDetails";
import CaptureUserForm from "./specialized/CaptureUserForm";
import Concept1 from "./task_management/Concept1";
import { datadogRum } from '@datadog/browser-rum';
import MatterDashboard from "./task_management/legal/MatterDashboard";
import MatterDetail from "./task_management/legal/MatterDetail";
import Directory from "./task_management/Directory";
import CreatorProfile from "./creators/Profile";
import BondDashBoard from "./task_management/bonds/Dashboard";
import Reports from "./task_management/bonds/Reports";
import BondStep1 from "./task_management/bonds/bond-application/BondStep1";
import BondStep2 from "./task_management/bonds/bond-application/BondStep2";

window.Buffer = Buffer;

library.add(fas, faFontAwesome)

export const ThemeContext = createContext();
export const SitePropertiesContext = createContext(null);
export const APIContext = createContext(null);

datadogRum.init({
    applicationId: process.env.REACT_APP_DD_APLICATION_ID,
    clientToken: process.env.REACT_APP_DD_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service:process.env.REACT_APP_DD_SERVICE,
    env:'prod',
    // Specify a version number to identify the deployed version of your application in Datadog 
    // version: '1.0.0', 
    sessionSampleRate:100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel:'mask-user-input'
});
    
datadogRum.startSessionReplayRecording();

function App() {

  const [jUserStatus, setJUserStatus] = React.useState(null);
  const [sideMenuAPI, setSideMenuAPI] = React.useState(null);
  const [dashboardMenuAPI, setDashboardMenuAPI] = React.useState(null);
  const [themesAPI, setThemesAPI] = React.useState(null);
  const [bannersAPI, setBannersAPI] = React.useState(null);
  const [articlesAPI, setArticlesAPI] = React.useState(null);
  const [externalNetworksAPI, setExternalNetworksAPI] = React.useState(null);
  const [channelsAPI, setChannelsAPI] = React.useState(null);
  const [singleChannelAPI, setSingleChannelAPI] = React.useState(null);
  const [programsAPI, setProgramsAPI] = React.useState(null);
  const [mediaStoreAPI, setMediaStoreAPI] = React.useState(null);
  const [jbackendUserAPIKey, setJbackendUserAPIKey] = React.useState(null);
  const [sitePropertiesAPI, setSitePropertiesAPI] = React.useState(null);
  const [formsAPI, setFormsAPI] = React.useState(null);
  const [floorPlansAPI, setFloorPlansAPI] = React.useState(null);

  const [articles, setArticles] = React.useState(null);
  const [channelCategories, setChannelCategories] = React.useState(null);
  const [sideMenu, setSideMenu] = React.useState(null);
  const [dashboardMenu, setDashboardMenu] = React.useState(null);
  const [theme, setTheme] = React.useState(null);
  const [siteProperties, setSiteProperties] = React.useState(null);
  const [pageBackground, setPageBackground] = React.useState(null);
  const [banners, setBanners] = React.useState(null);
  const [baseUrl, setBaseUrl] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [showBanner, setShowBanner] = React.useState(true);
  const [networkName, setNetworkName] = React.useState('');
  const [broadcastAPI, setBroadcastAPI] = React.useState(null);
  const [categoryAPI, setCategoryAPI] = React.useState(null)
  const [formFields, setFormFields] = React.useState(null);
  const [initialLoad, setInitialLoad] = React.useState(true);
  

  var hostname = window.location.hostname;
  hostname = hostname.split(".");
  hostname = hostname[0];
  // if(hostname.length > 2) {
  //   hostname = hostname[0];
  // } else {
  //   hostname = "shelcaster-tv";
  // }
 
  localStorage.setItem("clientId", hostname);
  localStorage.setItem("shelcasterUrl", window.location.href);
  const [clientID, setClientID] = React.useState(hostname);

  function removeBanner() {
      setShowBanner(false)
  }

  // const handleSearch = (e) => {
  //   if(e.target.value.length > 2) {
  //     console.log("search", e.target.value);
  //   }
    
  // }

  //API to call host (primary) network

  React.useEffect(() => {
    async function getNetworks() {
        const response = await fetch(`${process.env.REACT_APP_SHELCASTER_NETWORKS_ENDPOINT}?clientID=${clientID}`);
        const networks = await response.json();
        
        // if(!clientID){
        //     window.location.hostname = "https://shelcaster-tv.shelcaster.com";
        // }
        return networks;
    }
    getNetworks().then(networks => {
        setSideMenuAPI(networks[0].sidemenu_api);
        setDashboardMenuAPI(networks[0].dashboard_menu_api);
        setThemesAPI(networks[0].themes_api);
        setBannersAPI(networks[0].banners_api);
        setArticlesAPI(networks[0].articles_api);
        setExternalNetworksAPI(networks[0].external_networks_api);
        setBaseUrl(networks[0].base_url);
        setBroadcastAPI(networks[0].broadcast_api);
        setChannelsAPI(networks[0].channels_api);
        setSingleChannelAPI(networks[0].channel_single_api);
        setProgramsAPI(networks[0].programs_api);
        setMediaStoreAPI(networks[0].media_store_api);
        setJbackendUserAPIKey(networks[0].jbackend_api_key);
        setSitePropertiesAPI(networks[0].site_properties_api);
        setFormsAPI(networks[0].forms_api);
        setCategoryAPI(networks[0].channel_categories_api);
        setFloorPlansAPI(networks[0].floor_plans_api);
        console.log("floor_plans_api", networks[0].floor_plans_api);
        // console.log("Site Properties in setAPI", sitePropertiesAPI);

        localStorage.setItem("baseUrl", networks[0].base_url);
    },
    (error) => {
        console.log("get networks", error.message);
    });

  },[clientID, bannersAPI])
  const { categories, loading, error } = useChannelCategories(categoryAPI);
  const channels = useChannels(process.env.REACT_APP_CHANNEL_ENDPOINT);
  console.log("Categories in hook", categories);
  // API to call list of external (shared) networks
  const categoryArray = [];
  const groupBy = (array, key) => {
      // Return the end result
      return array.reduce((result, currentValue) => {
        // If an array already present for key, push it to the array. Else create an array and push the object
        (result[currentValue[key]] = result[currentValue[key]] || []).push(
          currentValue
        );
        // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
        return result;
      }, {}); // empty object is the initial value for result object
  };

  React.useEffect(() => {
    async function getExternalNetworks() {
        const response = await fetch(externalNetworksAPI);
        const extNetworks = await response.json();
        return extNetworks;
    }

    getExternalNetworks().then(extNetworks => {
        extNetworks.map((network, i) => {
            async function getCategories() {
                const res = await fetch(network.channel_categories_api);
                const data = await res.json();
                return data;
            }

            getCategories().then(data => {
                // console.log("categories data");
                // console.log("data", data);
                // console.log("Channels from MySQL", network.channels_api);
                // console.log("Channels from Lambda", process.env.REACT_APP_CHANNEL_ENDPOINT);
                if(data) {
                  data.map((channelSet, j) => {
                      fetch(`${network.channels_api}?categoryID=${channelSet.id}`)
                      .then(resp => resp.json())
                      .then(resp => {
                          channelSet['channels'] = resp;
                          channelSet['programAPI'] = network.programs_api;
                          channelSet['networkID'] = network.id;
                          channelSet['ordering'] = network.ordering;
                          categoryArray.push(channelSet);
                          // console.log("channel set resp", categoryArray);
                      },
                      (error) => {
                          console.log("channel set", error.message);
                      })
                  },
                  (error) => {
                      console.log("map categories", error.message);
                  })
                }
            },
            (error) => {
                console.log("get categories", error.message);
            })
        })
    })
    .then(() => {
        setTimeout(() => {

            categoryArray.sort(function(a, b){
                let x = a.title.toLowerCase();
                let y = b.title.toLowerCase();
                if (x < y) {return -1;}
                if (x > y) {return 1;}
                return 0;
            });
              
            let categoryGrouped = groupBy(categoryArray, 'ordering');
            var categoryArrayGrouped = Object.keys(categoryGrouped).map(function(key) {
                return [Number(key), categoryGrouped[key]];
            });
            setChannelCategories(categoryArrayGrouped);
            // console.log("chanelsArrayGroupd", categoryArrayGrouped);
            if(initialLoad == true) {
              setIsLoading(false);
            }
            
        },6000);
        
    },(error) => {
        console.log("external networks", error.message);
    });
  },[externalNetworksAPI, categories]);

  React.useEffect(() => {
    fetch(`${articlesAPI}?articleCategory=dashboard`)
    .then(result => result.json())
    .then(result => {
        if(result) {
          setArticles(result);
        }
    },
    (error) => {
        console.log(error.message);
    })
  },[articlesAPI])

  React.useEffect(() => {
    fetch(sideMenuAPI)
    .then(result => result.json())
    .then(result => {
        setSideMenu(result);
        // console.log("side menu in app", result)
    })
  },[sideMenuAPI])

  React.useEffect(() => {
    fetch(dashboardMenuAPI)
    .then(result => result.json())
    .then(result => {
        setDashboardMenu(result);
    })
  },[dashboardMenuAPI])

  React.useEffect(() => {
    fetch(bannersAPI)
    .then(result => result.json())
    .then(result => {
        setBanners(result);
    },
    (error) => {
        console.log(error.message);
    })
  }, [bannersAPI])

  React.useEffect(() => {
    fetch(themesAPI)
    .then(result => result.json())
    .then(result => {
        setTheme(result);
        localStorage.setItem("theme", result);
        
        setInitialLoad(false);
        console.log("THEME", theme[0]);
    })
  },[themesAPI])
  
  React.useEffect(() => {
    fetch(sitePropertiesAPI)
    .then(result => result.json())
    .then(result => {
      setSiteProperties(result);
      setNetworkName(result[0].app_title);
    }, 
    (error) => {
      console.log("Site properties Error", error.message);
    })
  }, [sitePropertiesAPI])

  React.useEffect(() => {
    fetch(formsAPI)
    .then(result => result.json())
    .then(result => {
      // console.log("form fields", result);
      setFormFields(result);
    },
    (error) =>{
      console.log("Forms Error", error.message);
    })
  }, [formsAPI])

  return (
    <ThemeContext.Provider value={theme}>
      <Helmet>
        <title>{process.env.REACT_APP_META_TITLE}</title>
        <meta name="description" content={process.env.REACT_APP_META_DESCRIPTION} />
        <meta property="og:image" content={process.env.REACT_APP_META_IMAGE} />
        {/* Add more meta tags as needed */}
      </Helmet>
      {theme && <SitePropertiesContext.Provider value={siteProperties}>
        <DndProvider backend={HTML5Backend}>
          <div style={{backgroundImage: `url(${process.env.REACT_APP_BACKGROUND_IMAGE})`, backgroundSize: 'cover'}}>
            {isLoading ? (
              <div className="pulse"><img style={{maxWidth: '80%', margin: '100px auto'}} src={process.env.REACT_APP_NETWORK_LOGO} alt="Shelcaster"/></div>
            ) : (
              <Router>
              <AuthProviderAWS>
              {<DenseAppBar sideMenu={sideMenu} baseUrl={baseUrl} networkName={networkName} channelCategories={channelCategories}/>}
              {showBanner && <Banners bannersAPI={bannersAPI} />}
              <Container fluid className="d-flex align-items-center justify-content-center g-0 pageWrapper" style={{ minHeight: "100vh", height: '100%', paddingTop: '70px', background: `${theme[0].page_background_color} url(${baseUrl + theme[0].page_background_image}) repeat-y`, backgroundSize: 'cover', backgroundRepeat: `${theme[0].page_background_image_repeat}`, color: `${theme[0].page_font_color}`, padding: '12px'}}>
                <div className="w-100" style={{alignSelf: 'start'}}>
                    
                      <Routes>
                        {/* <Route exact path="/" element={<PrivateRoute/>}>
                          <Route exact path="/" element={<Dashboard baseUrl={baseUrl} bannersAPI={bannersAPI} dashboardMenu={dashboardMenu} sideMenu={sideMenu}/>}/>
                        </Route> */}
                        <Route path="/dashboard" element={<PrivateRoute/>}>
                          <Route exact path="/dashboard" element={<Dashboard baseUrl={baseUrl} bannersAPI={bannersAPI} sideMenu={sideMenu} dashboardMenu={dashboardMenu}/>}/>
                        </Route>
                        {/* <Route exact path="/" element={<PrivateRoute/>}>
                          <Route exact path="/" element={<Broadcast channelCategories={channelCategories} baseUrl={baseUrl} onChannelClick={removeBanner} theme={theme}/>}/>
                        </Route> */}
                        <Route exact path="/profile" element={<PrivateRoute/>}>
                          <Route exact path="/profile" element={<Profile categoryAPI={categoryAPI} />}/>
                        </Route>
                        {/* <Route exact path="/directory" element={<PrivateRoute/>}>
                          <Route exact path="/directory" element={<UserList/>}/>
                        </Route> */}
                        <Route exact path="/update-profile" element={<PrivateRoute/>}>
                          <Route exact path="/update-profile" element={<UpdateProfile/>}/>
                        </Route>
                        <Route exact path="/file-upload" element={<PrivateRoute/>}>
                          <Route exact path="/file-upload" element={<UploadFormFB/>}/>
                        </Route>
                        {/* <Route path="/broadcast" element={<PrivateRoute/>}> */}
                          <Route path="/broadcast/:channelId/:programId" element={<Broadcast programsAPI={programsAPI} channelCategories={channelCategories} singleChannelAPI={singleChannelAPI} baseUrl={baseUrl} onChannelClick={removeBanner} bannersAPI={bannersAPI} />}/>
                          <Route path="/broadcast/:channelId" element={<Broadcast programsAPI={programsAPI} channelCategories={channelCategories} singleChannelAPI={singleChannelAPI} baseUrl={baseUrl} onChannelClick={removeBanner} bannersAPI={bannersAPI} />}/>
                          <Route path="/broadcast/:channelAlias/:programId" element={<Broadcast programsAPI={programsAPI} channelCategories={channelCategories} singleChannelAPI={singleChannelAPI} baseUrl={baseUrl} onChannelClick={removeBanner} bannersAPI={bannersAPI} />}/>
                          <Route path="/broadcast/:channelAlias" element={<Broadcast programsAPI={programsAPI} channelCategories={channelCategories} singleChannelAPI={singleChannelAPI} baseUrl={baseUrl} onChannelClick={removeBanner} bannersAPI={bannersAPI} />}/>
                          <Route exact path="/broadcast" element={<Broadcast channelCategories={channelCategories} baseUrl={baseUrl} onChannelClick={removeBanner} bannersAPI={bannersAPI} />}/>
                        {/* </Route> */}
                        <Route path="/media-center" element={<PrivateRoute/>}>
                          <Route exact path="/media-center" element={<MediaCenter/>}/>
                        </Route>
                        <Route path="/media-store" element={<PrivateRoute />}>
                          <Route path="/media-store" element={<MediaStore mediaStoreAPI={mediaStoreAPI} />}/>
                        </Route>
                        <Route path="/signin" element={<SignIn />}/>
                        <Route path="/forgot-password" element={<ForgotPassword />}/>
                        <Route exact path="/register" element={<Register />}/>

                        <Route path="/prices" element={<Prices />}/>
                        <Route path="/subscribe" element={<Subscribe />}/>
                        <Route path="/account" element={<Account />}/>
                        <Route path="/cancel" element={<Cancel />}/>

                        <Route path="/payments-dashboard" element={<PrivateRoute />}>
                          <Route path="/payments-dashboard" element={<PaymentsDashboard/>}/>
                          <Route path="checkout" element={<CheckOut/>}/>
                        </Route>
                        <Route path="/add-contacts" element={<AddContacts />}/>
                        <Route path="/qrcode" element={<MyQRCode />}/>
                        <Route path="/admin/:register" element={<AuthForm/>}/>
                        <Route path="/memberships" element={<PrivateRoute/>}>
                          <Route path="/memberships/membership-tiers" element={<MembershipTiers/>}/>
                          <Route path="/memberships/membership-details" element={<MembershipshipDetails />}/>
                        </Route>
                        <Route path="/edit-profile" element={<PrivateRoute/>}>
                          <Route path="/edit-profile" element={<EditProfile />}/>
                        </Route>
                        <Route path="/media-center" element={<PrivateRoute/>}>
                          <Route path="/media-center" element={<EditProfile />}/>
                        </Route>
                        <Route exact path="/floor-plans" element={<FloorPlans floorPlansAPI={floorPlansAPI} />}/>
                        <Route path="/details/:floor_plan_id" element={<FloorPlanDetails/>} />
                        <Route path="/learn-more/:floor_plan_id/images/plans/:floor_plan_image/:door_code" element={<CaptureUserForm/>}/>
                        <Route path="/concept1" element={<Concept1/>}/>
                        <Route path="/matter-dashboard" element={<MatterDashboard/>}/>
                        <Route path="/matter-detail" element={<MatterDetail />}/>
                        <Route path="/surety-directory" elementr={<PrivateRoute/>}>
                           <Route path="/surety-directory" element={<Directory type="surety" title="Surety Directory" />}/>
                        </Route>
                        <Route path="/client-directory" element={<PrivateRoute/>}>
                           <Route path="/client-directory" element={<Directory type="client" title="Client Directory" />}/>
                        </Route>
                        <Route path="/attorney-directory" element={<PrivateRoute/>}>
                           <Route path="/attorney-directory" element={<Directory type="attorney" title="Attorney Directory" />}/>
                        </Route>
                        <Route path="/creator-profile" element={<CreatorProfile/>}/>
                        <Route path="/" element={<PrivateRoute/>}>
                          <Route path="/" element={<BondDashBoard />}/>
                        </Route>
                        <Route path="/bond-reports" element={<PrivateRoute/>}>
                          <Route path="/bond-reports" element={<Reports />}/>
                        </Route>
                        <Route path="/bond-application-step-1" element={<BondStep1 />}/>
                        <Route path="/bond-application-step-2" element={<BondStep2 />}/>
                      </Routes>
                    

                </div>
              </Container>
              </AuthProviderAWS>
            </Router>
            )}
          </div>
        </DndProvider>
      </SitePropertiesContext.Provider>}
    </ThemeContext.Provider>
  );
}

export default App;
