import { useState } from 'react';
import { Auth } from 'aws-amplify';
import { Amplify } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';

import awsExports from '../../../aws-exports';
Amplify.configure(awsExports);
const clientId = localStorage.getItem("clientId");

const SignUp = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [birthdate, setBirthdate] = useState('');
    const [phone, setPhone] = useState('');
    const [networks, setNetworks] = useState(clientId);

    const signUp = async () => {
        try {
            await Auth.signUp({
                username,
                password,
                attributes: {
                    'phone_number': phone,
                    'given_name': firstName,
                    'family_name': lastName,
                    'birthdate': birthdate,
                    'email': username,
                    'custom:clients': networks,
                }
            });
            console.log('user successfully signed up!');
        } catch (error) {
            console.log('error signing up:', error);
        }
    }

    return (
        <div>
            <input onChange={(event) => setUsername(event.target.value)} placeholder='Username'/>
            <input onChange={(event) => setPassword(event.target.value)} placeholder='Password' type='password'/>
            <input onChange={(event) => setFirstName(event.target.value)} placeholder='First Name'/>
            <input onChange={(event) => setLastName(event.target.value)} placeholder='Last Name'/>
            <input onChange={(event) => setPhone(event.target.value)} placeholder='Phone'/>
            <input onChange={(event) => setBirthdate(event.target.value)} placeholder='Birthdate'/>
            <button onClick={signUp}>Sign Up</button>
        </div>
    );
}

export default SignUp;
