import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import AdminDashboard from '../../admin/AdminDashboard';

const SignIn = ({ onSignIn }) => {
    const clientId = localStorage.getItem("clientId");

    return (
        <div>
            <AdminDashboard clientId={clientId} />
        </div>
    );
}

export default SignIn;
