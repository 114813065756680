import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import TemporaryDrawer from '../web-components/Drawer';
import Grid from '@mui/material/Grid';
import { SitePropertiesContext, ThemeContext } from '../App';
import './appbar.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
// import { auth } from '../../firebase';
import { Form} from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import Payments from '../stripe/Payments';
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useContext, useState, useEffect } from 'react';
import { AuthContextAWS } from '../../contexts/AuthContextAWS';
import SubscribeButton from '../stripe/buttons/SubscribeButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';


export default function DenseAppBar(props) {
  const theme = useContext(ThemeContext);
  const auth = useContext(AuthContextAWS);
  const userAttributes = auth.attributes;
  
  
  // console.log("current user appbar", picture.Value);
  const [priceId, setPriceId] = useState(null);
  const [productId, setProductId] = useState(null);
  const siteProperties = useContext(SitePropertiesContext);

  const [currentUser, setCurrentUser] = useState(auth.currentUser);

  function generate(element) {
    return [0, 1, 2].map((value) =>
      React.cloneElement(element, {
        key: value,
      }),
    );
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    borderRadius: '12px',
    boxShadow: 24,
    p: 4,
  };
  const [baseUrl, setBaseUrl] = useState(props.baseUrl);
  const networkName = props.networkName ? props.networkName : localStorage.getItem("networkName");
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openPopoverId, setOpenPopoverId] = useState(null);
  const [avatar, setAvatar] = useState(null);

  useEffect(()=>{
    if(userAttributes) {
      const picture = userAttributes.find(attribute => attribute.Name === 'picture');
      setAvatar("https://cpibonding.app/images/avatar.png");
      //userAttributes.find(attribute => attribute.Name === 'picture').value
      console.log("user avatar", avatar);
    }
  },[userAttributes])

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  const handleLogout = () => {
    auth.signOut()
    .then(()=>{
      fetch('https://shelcaster.tv/user-logout?action=post&module=user&resource=logout')
    })
    .then(() => {
      setCurrentUser(auth.currentUser);
    })
      
  }

  //Stripe Code
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = (e) => {
      e.stopPropagation();
      setOpenModal(true);
  }
  const handleCloseModal = (e) => {
      e.stopPropagation();
      setOpenModal(false);
  }
  
  return (
    <SitePropertiesContext.Provider value={siteProperties}>
      <Box sx={{ flexGrow: 1 }} className="appbarHeader">
        <AppBar position="static">
          <Toolbar variant="dense" style={{backgroundColor: theme[0].title_bar_background_color, color: theme[0].title_bar_font_color}}>
            <Grid container>
              <Grid item xs={1} md={2}>
                <div edge="start" aria-label="menu">
                  {theme && <TemporaryDrawer sideMenu={props.sideMenu} />}
                </div>
              </Grid>
              <Grid item xs={9} md={6} style={{textAlign: 'center'}}>
                {theme[0].logo ? ( <img className="logoProperties" src={baseUrl + theme[0].logo} style={{maxWidth: '100%', padding: '6px 0 6px 0', margin: '4px'}} sx={4}/>) : (
                  <h1 style={{lineHeight: '1.7'}} className="mainLogo">{networkName}</h1>
                )}
              </Grid>
              {currentUser && siteProperties[0].subscription_required === '1' && <Grid item xs={4} md={2}>
                <SubscribeButton appTitle={siteProperties[0].app_title} networkPurchaseButton={siteProperties[0].network_purchase_button} networkPurchaseLabel={siteProperties[0].network_purchase_label} />
                
                {/* <div onClick={handleOpenModal}>
                  <Button className="subscribeButton" style={{display: 'block', verticleAlign: 'middle', backgroundColor:'#000', color: '#fff', marginTop: '24px', padding: '3px 3px 0 3px', textAlign: 'center'}}>Subscribe <FontAwesomeIcon icon={solid('dollar-sign')} /></Button>
                </div>
                <Modal
                    open={openModal}
                    onClose={handleCloseModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                    <Payments state={{id: 'network-subscription1', subscription_plan: 'monthly-commercial', price: siteProperties[0].subscription_network_price, payment_frequency: siteProperties[0].subscription_network_frequency, description: 'Monthly Subscription', title:siteProperties[0].app_title, stripeProductId: productId, stripePriceId: priceId, debug: siteProperties[0].debug}}/>
                    </Box>
                </Modal> */}
                
              </Grid>}
              <Grid item xs={4} md={4} style={{display: 'flex', justifyContent: 'end'}}>
                <List dense={false}>
                    <ListItem>
                      <ListItemAvatar>
                      <Avatar alt="Remy Sharp" src={avatar} />
                      </ListItemAvatar>
                      <ListItemText
                        primary="Jared Holt"
                        secondary={false ? 'Secondary text' : null}
                      />
                    </ListItem>
                </List>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </Box>
    </SitePropertiesContext.Provider>
  );
}
