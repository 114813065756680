import React from 'react';
import './dashboardTable.scss';
export const DashboardTable = ({ size, ...props }) => {
  return (
    <div className="dashboardTableContainer">
      <table className='storybook-dashboardTable'>
        <tr className='storybook-dashboardTable--header'>
          <th className='storybook-dashboardTable--header--cause'>Cause #</th>
          <th className='storybook-dashboardTable--header--prinicpal'>
            Principal
          </th>
          <th className='storybook-dashboardTable--header--date'>Date</th>
          <th className='storybook-dashboardTable--header--ward_estate'>
            Ward/Estate
          </th>
          <th className='storybook-dashboardTable--header--attorney'>
            Attorney
          </th>
          <th className='storybook-dashboardTable--header--amount'>Amount</th>
          <th className='storybook-dashboardTable--header--status'>Status</th>
        </tr>
        {props.items.map((item, index) => (
          <tr
            className='storybook-dashboardTableRow'
            key={index}
          >
            <td
              className={[
                'storybook-dashboardTable--cause',
                'storybook-dashboardTable--data',
              ].join(' ')}
            >
              {item.cause}
            </td>
            <td className='storybook-dashboardTable--data'>{item.principal}</td>
            <td className='storybook-dashboardTable--data'>{item.date}</td>
            <td className='storybook-dashboardTable--data'>
              {item.ward_estate}
            </td>
            <td className='storybook-dashboardTable--data'>{item.attorney}</td>
            <td className='storybook-dashboardTable--data'>{item.amount}</td>
            <td
              className={[
                'storybook-dashboardTable--data',
                'storybook-dashboardTable--status',
              ].join(' ')}
            >
              <span className={`storybook-dashboardTable--${item.status[1]}`}>
                {item.status[0]}
              </span>
            </td>
          </tr>
        ))}
      </table>
    </div>
  );
};
