/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:673a1f8e-541e-45ee-a5c9-03592819ddf1",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_GQUK3a0S5",
    "aws_user_pools_web_client_id": "utr684lk2drft007tmgfudbu6",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "users-mycognito",
            "region": "us-east-1"
        },
        {
            "tableName": "channelCategories-mycognito",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://aslojvwwxnhnlf3pou5txiarn4.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-cxhok3iw3vhj7gthy65k7y5s34",
    "aws_cloud_logic_custom": [
        {
            "name": "channelCategoryApi",
            "endpoint": "https://w2oebdtvgc.execute-api.us-east-1.amazonaws.com/mycognito",
            "region": "us-east-1"
        }
    ],
    "COGNITO": {
        "REGION": "us-east-1",
        "USER_POOL_ID": "us-east-1_whIiBhpUx",
        "USER_POOL_APP_CLIENT_ID": "2igppfktutuplhtlpkjj3u6j4v"
    }
};


export default awsmobile;
