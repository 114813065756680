import React, { useState, useEffect, useContext } from 'react';
import { Auth } from 'aws-amplify';
import { Card, CardContent, CardMedia, Typography, CardActions, Button, Link as MuiLink, ListItem, List } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ThemeContext } from '../App';
import UserTabs from '../web-components/TabPanel';
import BroadcastPreview from '../admin/broadcast/BroadcastPreview';
import QRCode from "react-qr-code";

const Profile = (props) => {
  console.log("category api from profile", props.categoryAPI);
    const [userAttributes, setUserAttributes] = useState(null);
    const [vcfUrl, setVcfUrl] = useState(null);
    const theme = useContext(ThemeContext);
    const navigate = useNavigate();
    useEffect(() => {
      const getUserAttributes = async () => {
        try {
          const currentUser = await Auth.currentAuthenticatedUser();
          const attributes = currentUser.attributes; // Get the user attributes
          setUserAttributes(attributes);
          setVcfUrl(attributes['custom:qr_code_vcard']);
        } catch (error) {
          console.error('Error fetching user attributes:', error);
        }
      };
  
      getUserAttributes();
    }, []); // The empty array means this effect will run once, similar to componentDidMount in class components

    const handleEditProfile = function() {
      navigate('/edit-profile');
    }
  
    if (!userAttributes) {
      return <div>Loading...</div>;
    }
  
    return (
      <div style={{padding: '24px'}}>
        <h1>User Profile</h1>
        <div style={{display: 'flex', gap: '12px', flexWrap: 'wrap'}}>
          <Card xs={400} style={{ backgroundColor: theme[0].page_background_color, color: theme[0].page_font_color, maxWidth: '320px', boxShadow: '6px 6px 12px #000' }}>
            
            <CardContent>
              <Typography variant="h5">{userAttributes['given_name'] + ' ' + userAttributes['family_name']}</Typography>
              {/* <Link component={MuiLink} style={{ color: theme[0].page_font_color, textDecoration: 'underline' }} to="/edit-profile">
                Edit Profile
              </Link> */}
            </CardContent>
            <CardMedia
              component="img"
              image={userAttributes['picture']}
              alt={userAttributes['given_name']}
            />
            <List>
              {userAttributes['phone_number'] && <ListItem>
                <Typography variant="body">Phone: {userAttributes['phone_number']}</Typography>
              </ListItem>}
              {userAttributes['email'] && <ListItem>
                <Typography variant="body">Email: {userAttributes['email']}</Typography>
              </ListItem>}
              {vcfUrl && <ListItem>
                <QRCode value={vcfUrl} size={256} />
              </ListItem>}
            </List>
            <CardActions>
              <Button size="small" style={{color: theme[0].page_font_color}} onClick={handleEditProfile}><FontAwesomeIcon icon="fa-solid fa-pen" /> &nbsp;Edit</Button>
            </CardActions>
          </Card>
          {/* <Card style={{flexGrow:'2'}}>
            <CardContent>
              <Typography variant="h5">
                My Media
              </Typography>
              <BroadcastPreview categoryAPI={props.categoryAPI}/>
            </CardContent>
          </Card> */}
          <UserTabs categoryAPI={props.categoryAPI}/>
          {/* <Card style={{ backgroundColor: theme[0].page_background_color, color: theme[0].page_font_color, boxShadow: '6px 6px 12px #000', maxWidth: '100%' }}>
            <CardMedia
              component="img"
              image={userAttributes['picture']}
              alt={userAttributes['given_name']}
            />
            <CardActions>
              <Button size="small" style={{color: theme[0].page_font_color}} onClick={handleEditProfile}><FontAwesomeIcon icon="fa-solid fa-pen" /> &nbsp;Edit</Button>
            </CardActions>
          </Card> */}
        </div>
      </div>
    );
  };
  
  export default Profile;
  