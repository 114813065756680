import React, { useState } from 'react';
import './bondStep1.scss';
import { Box, Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material';

const BondStep2 = () => {
    const [applicationType, setApplicationType] = useState('');
    const [downloadStatus, setDownloadStatus] = useState('');
    const handleChange = (event) => {
        setApplicationType(event.target.value);
    };
    return (
        <div className="bondApplication step1">
            <div className="topPage">
                <h1>Bond Application</h1>
                <h2>Step 2: Read & Agree</h2>
                
            </div>
            <div className="bottomPage">
                <Box sx={{ maxWidth: 600, margin: '36px auto' }}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Application Type</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={downloadStatus}
                            label="Select Application Type"
                            onChange={handleChange}
                            >
                            <MenuItem value="bond 1">Bond 1</MenuItem>
                            <MenuItem value="bond 2">Bond 2</MenuItem>
                            <MenuItem value="bond 3">Bond 3</MenuItem>
                            <MenuItem value="bond 4">Bond 4</MenuItem>
                        </Select>
                    </FormControl>
                    <Button variant="contained" fullWidth style={{textTransform: 'uppercase', backgroundColor: '#e4ddc4', marginTop: '12px', color: '#8f876a'}}>I Agree</Button>
                </Box>
            </div>
        </div>
    )
}

export default BondStep2;