import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Button, CardMedia, Typography } from '@mui/material';
import QRCode from 'qrcode.react';

const FloorPlanDetails = () => {
  const { floor_plan_id } = useParams();
  const baseUrl = localStorage.getItem("baseUrl");
  const clientId = localStorage.getItem("clientId");
  const floorPlansAPI = localStorage.getItem("floorPlansAPI");
  const [floorPlan, setFloorPlan] = useState(null);
  const [floor_plan_image, setFloorPlanImage] = useState(null);
  const [door_code, setDoorCode] = useState(null);
  
  useEffect(()=> {
    fetch(`${floorPlansAPI}?floor_plan_id=${floor_plan_id}`)
    .then(result => result.json())
    .then(result => {
        setFloorPlan(result);
        console.log("floor plan result", result[0].floor_plan_image);
        setFloorPlanImage(result[0].floor_plan_image);
        setDoorCode(result[0].door_code);
        
    })
}, []);

  const downloadQRCode = () => {
    const canvas = document.getElementById('qrcode-canvas');
    const image = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
    const link = document.createElement('a');
    link.href = image;
    link.download = `floor-plan-${floor_plan_id}.png`;
    link.click();
  };

  return (
    <div style={{display: 'flex'}}>
      {floorPlan && floorPlan.map((plan, i)=>(
      <Card key={i}>
        <CardMedia>
            <img src={`${baseUrl}${plan.floor_plan_image}`} style={{width: '100%', maxWidth:'600px', flexWrap: 'wrap'}} alt="Thumbnail" />
        </CardMedia>
        <CardContent>
            <Typography variant="h6">{plan.floor_plan_id}</Typography>
            <Typography>Floors: {plan.floors}</Typography>
            <Typography>Bathrooms: {plan.bathrooms}</Typography>
            <Typography>Garage: {plan.garage}</Typography>
            <Typography>Total Square Feet: {plan.total_square_feet}</Typography>
        </CardContent>
      </Card>
      ))};
      <Card >
        <CardMedia>
            <QRCode id="qrcode-canvas" value={`https://${clientId}.${process.env.REACT_APP_PLATFORM_URL}/learn-more/${floor_plan_id}/${floor_plan_image}/${door_code}`} size={1000} style={{width: '100%', maxWidth:'240px', maxHeight: 240}} />
        </CardMedia>
        <CardContent>
            <Button variant="contained" onClick={downloadQRCode}>
                Download Hi Res QR Code
            </Button>
        </CardContent>
      </Card>
    </div>
  );
};

export default FloorPlanDetails;
