
import useChannelCategories from '../../../hooks/useChannelCategories';
import React, { useState } from 'react';
import { List, ListItem, ListItemText, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import ChannelCategoryBuilder from './ChannelCategoryBuilder';
import ControlledAccordions from '../../web-components/Accordion';


const ChannelCategoryList = (props) => {
    const clientId = localStorage.getItem("clientId");
    const apiEndpointCategories = `${props.categoryAPI}`; 
    const { categories, loading, error } = useChannelCategories(apiEndpointCategories);
    const [selectedItem, setSelectedItem] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;
    const handleItemClick = (item) => {
        setSelectedItem(item);
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setSelectedItem(null);
        setDialogOpen(false);
    };

    return (
        <div>
            <ControlledAccordions setSelectedChannelId={props.setSelectedChannelId} items={categories} />
            {/* <List>
                {categories.map((category, index) => (
                <ListItem key={index} button onClick={() => handleItemClick(category)}>
                    <ListItemText primary={category.title} secondary={category.description} />
                </ListItem>
                ))}
            </List> */}
            <Dialog open={dialogOpen} onClose={handleCloseDialog}>
                {selectedItem && <DialogTitle>{selectedItem.title}</DialogTitle>}
                <DialogContent>
                    <ChannelCategoryBuilder editable={true} categoryDetails={selectedItem}/>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleCloseDialog} color="primary">
                    Close
                </Button>
                </DialogActions>
            </Dialog>

        </div>
    );
};

export default ChannelCategoryList;
