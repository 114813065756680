import React, { useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Select,
  MenuItem,
  Grid,
  Card,
  CardContent,
  Hidden,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField, // Added TextField for search
} from '@material-ui/core';
import { useTable, useSortBy, useFilters } from 'react-table';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
    backgroundColor: '#009edf',
    color: '#ffffff',
    borderRadius: '8px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
  },
  container: {
    marginTop: theme.spacing(2),
  },
  header: {
    marginBottom: theme.spacing(2),
  },
  filters: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
  },
  select: {
    marginRight: theme.spacing(2),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '200px',
    },
  },
  card: {
    marginBottom: theme.spacing(2),
    borderLeftWidth: '4px',
    borderLeftStyle: 'solid',
    '&.Completed': {
      borderLeftColor: '#096b19',
    },
    '&.Pending': {
      borderLeftColor: '#102cc9',
    },
    '&.InProgress': {
      borderLeftColor: '#8f6e51',
    },
    '&.DueWithin5Days': {
      borderLeftColor: '#ed7105',
    },
    '&.Overdue': {
      borderLeftColor: '#bf0404',
    },
  },
  search: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
}));

const columns = [
  {
    Header: 'Task ID',
    accessor: 'id',
  },
  {
    Header: 'Task Name',
    accessor: 'taskName',
  },
  {
    Header: 'Case Name',
    accessor: 'caseName',
  },
  {
    Header: 'Assigned To',
    accessor: 'assignedTo',
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: ({ value }) => {
      const statusColors = {
        Completed: '#096b19',
        Pending: '#102cc9',
        'In Progress': '#8f6e51',
        'Due within 5 days': '#ed7105',
        Overdue: '#bf0404',
      };
      return (
        <span style={{ color: statusColors[value] || '#000000' }}>{value}</span>
      );
    },
  },
  {
    Header: 'Deadline',
    accessor: 'deadline',
  },
];

const initialTasks = [
  {
    id: 1,
    taskName: 'Review Legal Documents',
    caseName: 'Jiminsky Evans v. City of Houston',
    assignedTo: 'John Doe',
    status: 'Completed',
    deadline: '2023-09-30',
  },
  {
    id: 2,
    taskName: 'Client Meeting',
    caseName: 'Smith v. Johnson',
    assignedTo: 'Jane Smith',
    status: 'Pending',
    deadline: '2023-09-15',
  },
  {
    id: 3,
    taskName: 'Research Case Law',
    caseName: 'Anderson v. Williams',
    assignedTo: 'Sarah Brown',
    status: 'In Progress',
    deadline: '2023-10-10',
  },
  {
    id: 4,
    taskName: 'Prepare Court Documents',
    caseName: 'Miller v. Davis',
    assignedTo: 'Michael Johnson',
    status: 'Pending',
    deadline: '2023-10-05',
  },
  {
    id: 5,
    taskName: 'Client Interview',
    caseName: 'Wilson v. Smith',
    assignedTo: 'Emily White',
    status: 'In Progress',
    deadline: '2023-09-25',
  },
  {
    id: 6,
    taskName: 'File Motion to Dismiss',
    caseName: 'Brown v. Johnson',
    assignedTo: 'David Lee',
    status: 'Completed',
    deadline: '2023-09-20',
  },
  {
    id: 7,
    taskName: 'Legal Research',
    caseName: 'Davis v. Wilson',
    assignedTo: 'Megan Adams',
    status: 'Due within 5 days',
    deadline: '2023-09-27',
  },
  {
    id: 8,
    taskName: 'Client Consultation',
    caseName: 'Johnson v. Miller',
    assignedTo: 'Mark Smith',
    status: 'Overdue',
    deadline: '2023-09-10',
  },
  {
    id: 9,
    taskName: 'Draft Settlement Agreement',
    caseName: 'Smith v. Davis',
    assignedTo: 'Laura Green',
    status: 'In Progress',
    deadline: '2023-10-15',
  },
  {
    id: 10,
    taskName: 'Legal Briefing',
    caseName: 'Williams v. Brown',
    assignedTo: 'Chris Turner',
    status: 'Pending',
    deadline: '2023-10-02',
  },
];

function Concept1() {
  const classes = useStyles();
  const [selectedTask, setSelectedTask] = useState(null);
  const [filterStatus, setFilterStatus] = useState('');
  const [filterCaseName, setFilterCaseName] = useState('');
  const [searchText, setSearchText] = useState(''); // Added searchText state
  const taskDetailsRef = useRef(null); // Create a ref for the task details container

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setFilter,
  } = useTable(
    {
      columns,
      data: initialTasks,
    },
    useFilters,
    useSortBy
  );

  const handleTaskClick = (task) => {
    setSelectedTask(task);
    // Scroll to the task details container when a task is clicked
    if (taskDetailsRef.current) {
      taskDetailsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const caseNames = Array.from(new Set(initialTasks.map((task) => task.caseName)));

  const filteredTasks = initialTasks.filter((task) => {
    if (filterStatus === '' && filterCaseName === '' && searchText === '') {
      return true;
    }
    if (filterStatus !== '' && filterStatus !== task.status) {
      return false;
    }
    if (
      filterCaseName !== '' &&
      !task.caseName.toLowerCase().includes(filterCaseName.toLowerCase())
    ) {
      return false;
    }
    if (
      searchText === '' ||
      task.taskName.toLowerCase().startsWith(searchText.toLowerCase()) // Filter by task name
    ) {
      return true;
    }
    return false;
  });  

  return (
    <div className={classes.root}>
      <Typography variant="h4" className={classes.header}>
        Law Firm Task Tracker
      </Typography>
      <div className={classes.container}>
        <div className={classes.filters}>
          <Select
            className={classes.select}
            value={filterStatus}
            onChange={(e) => {
              setFilter('status', e.target.value);
              setFilterStatus(e.target.value);
            }}
            displayEmpty
          >
            <MenuItem value="">Filter by Status</MenuItem>
            <MenuItem value="Completed">Completed</MenuItem>
            <MenuItem value="Pending">Pending</MenuItem>
            <MenuItem value="In Progress">In Progress</MenuItem>
            <MenuItem value="Due within 5 days">Due within 5 days</MenuItem>
            <MenuItem value="Overdue">Overdue</MenuItem>
          </Select>
          <Select
            className={classes.select}
            value={filterCaseName}
            onChange={(e) => {
              setFilter('caseName', e.target.value);
              setFilterCaseName(e.target.value);
            }}
            displayEmpty
          >
            <MenuItem value="">Filter by Case Name</MenuItem>
            {caseNames.map((caseName) => (
              <MenuItem key={caseName} value={caseName}>
                {caseName}
              </MenuItem>
            ))}
          </Select>
        </div>
        <TextField
          className={classes.search}
          label="Search"
          variant="outlined"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
        <Hidden smDown>
          <Grid container>
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table
                  {...getTableProps()}
                  className={classes.table}
                  aria-label="Task List"
                >
                  <TableHead>
                    {headerGroups.map((headerGroup) => (
                      <TableRow {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <TableCell
                            {...column.getHeaderProps(column.getSortByToggleProps())}
                          >
                            {column.render('Header')}
                            <span>
                              {column.isSorted
                                ? column.isSortedDesc
                                  ? ' 🔽'
                                  : ' 🔼'
                                : ''}
                            </span>
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableHead>
                  <TableBody {...getTableBodyProps()}>
                    {rows.map((row) => {
                      prepareRow(row);
                      return (
                        <TableRow
                          key={row.id}
                          className={
                            selectedTask === row.original ? classes.selectedRow : ''
                          }
                          onClick={() => handleTaskClick(row.original)}
                          {...row.getRowProps()}
                        >
                          {row.cells.map((cell) => {
                            return (
                              <TableCell {...cell.getCellProps()}>
                                {cell.render('Cell')}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Hidden>
        <Hidden mdUp>
          {filteredTasks.map((task) => (
            <Card
              key={task.id}
              className={`${classes.card} ${task.status.replace(/\s+/g, '')}`}
              onClick={() => handleTaskClick(task)}
            >
              <CardContent className={classes.cardContent}>
                <Typography variant="h6">Task Name: {task.taskName}</Typography>
                <Typography>Case Name: {task.caseName}</Typography>
                <Typography>Assigned To: {task.assignedTo}</Typography>
                <Typography>Status: {task.status}</Typography>
                <Typography>Deadline: {task.deadline}</Typography>
              </CardContent>
            </Card>
          ))}
        </Hidden>
        {selectedTask && (
          <div className={classes.taskDetails} ref={taskDetailsRef}>
            <Typography variant="h6" className={classes.header}>
              Task Details
            </Typography>
            <div>
              <strong>Task Name:</strong> {selectedTask.taskName}
            </div>
            <div>
              <strong>Case Name:</strong> {selectedTask.caseName}
            </div>
            <div>
              <strong>Assigned To:</strong> {selectedTask.assignedTo}
            </div>
            <div>
              <strong>Status:</strong> {selectedTask.status}
            </div>
            <div>
              <strong>Deadline:</strong> {selectedTask.deadline}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Concept1;
