import React from 'react';
import { DashboardTable } from './DashboardTable';
import { ReportsTable } from './ReportsTable';
import { DashboardCard } from './DashboardCard';
import './reports.scss';

const Reports = () => {
    const DailyCard = {
        header: 'paid premiums',
        type: 'paidPremiums',
        amount: '200',
        time: 'day',
        percent: '4.6',
        direction: 'up',
    }

    const YearlyCard = {
        header: 'paid premiums ytd',
        type: 'paidPremiumsYTD',
        amount: '7932',
        time: 'ytd',
        percent: '8',
        direction: 'down',
    }

    const LatePremiumsCard = {
        header: 'late premiums',
        type: 'paidPremiumsYTD',
        amount: '200',
        time: 'day',
        percent: '4.6',
        direction: 'up',
    };

    const ApprovedPremiumsCard = {
        header: 'approved premiums',
        type: 'approvedPremiums',
        amount: '100',
        time: 'day',
        percent: '2.3',
        direction: 'down',
    };

    const items = [
        {
            icon: 'fa-solid fa-print',
            staticReports: 'Late Premiums',
            dates: '06/01/2023-06/14/2023',
            value: '$83,500',
            priority: 'High',
            priorityColor: '#E74C3C',
            shortcut: ['Missed', '#673ab7'],
        },
        {
            icon: 'fa-solid fa-print',
            staticReports: 'Active Premiums',
            dates: '06/01/2023-06/14/2023',
            value: '$83,500',
            priority: 'Normal',
            priorityColor: '#F39C12',
            shortcut: ['All Paid', '#8bc34a'],
        },
        {
            icon: 'fa-solid fa-print',
            staticReports: 'Premiums Due (July 2023)',
            dates: '06/01/2023-06/14/2023',
            value: '$90,000',
            priority: 'Low',
            priorityColor: '#27AE60',
            shortcut: ['Processing', '#2196f3'],
        },
        {
            icon: 'fa-solid fa-print',
            staticReports: 'Premiums Due (Aug 2023)',
            dates: '06/01/2023-06/14/2023',
            value: '$105,200',
            priority: 'Normal',
            priorityColor: '#F39C12',
            shortcut: ['Waiting', '#f6d433'],
        },
        {
            icon: 'fa-solid fa-print',
            staticReports: 'Late Premiums',
            dates: '06/01/2023-06/14/2023',
            value: '$83,500',
            priority: 'High',
            priorityColor: '#E74C3C',
            shortcut: ['Missed', '#673ab7'],
        },
        {
            icon: 'fa-solid fa-print',
            staticReports: 'Active Premiums',
            dates: '06/01/2023-06/14/2023',
            value: '$83,500',
            priority: 'Normal',
            priorityColor: '#F39C12',
            shortcut: ['All Paid', '#8bc34a'],
        },
        {
            icon: 'fa-solid fa-print',
            staticReports: 'Premiums Due (July 2023)',
            dates: '06/01/2023-06/14/2023',
            value: '$90,000',
            priority: 'Low',
            priorityColor: '#27AE60',
            shortcut: ['Processing', '#2196f3'],
        },
        {
            icon: 'fa-solid fa-print',
            staticReports: 'Premiums Due (Aug 2023)',
            dates: '06/01/2023-06/14/2023',
            value: '$105,200',
            priority: 'Normal',
            priorityColor: '#F39C12',
            shortcut: ['Waiting', '#f6d433'],
        },
    ];

    return (
        <div className="reportsContainer">
            <h1>Reports</h1>
            <div className="premiumCards">
                <DashboardCard cardDetails={LatePremiumsCard}/>
                <DashboardCard cardDetails={ApprovedPremiumsCard}/>
                <DashboardCard cardDetails={DailyCard}/>
                <DashboardCard cardDetails={YearlyCard}/>
            </div>
            <ReportsTable items={items} />
        </div>
    )
}

export default Reports;