import React, { useContext, useEffect } from 'react'
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { AuthContextAWS } from '../../contexts/AuthContextAWS';

const PrivateRoute = (props) => {
    const { isAuthenticated } = useContext(AuthContextAWS);
    console.log("isAuthenticated", isAuthenticated);
    const navigate = useNavigate();
    const location = useLocation();
    useEffect(() => {
        if (!isAuthenticated) {
            localStorage.setItem('desiredDestination', location.pathname);
            navigate('/signin');
        }
    }, [isAuthenticated]);
    return isAuthenticated ? <Outlet {...props} /> : null;
    // return currentUser ? <Outlet /> : <Navigate to="/signin" />;
}
export default PrivateRoute;