import { useCallback, useState } from 'react';
import { DndProvider, useDrag } from 'react-dnd';
import { useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend';

const Program = ({ id, url }) => {
    const [{ isDragging }, drag] = useDrag({
      type: 'program',
      item: { id },
      collect: monitor => ({
        isDragging: !!monitor.isDragging(),
      }),
    })
  
    return (
      <div ref={drag} style={{ opacity: isDragging ? 0.5 : 1 }}>
        {url} test
      </div>
    )
}

const ProgramContainer = ({ onDrop }) => {
    const [{ isOver }, drop] = useDrop({
      accept: 'program',
      drop: onDrop,
      collect: monitor => ({
        isOver: !!monitor.isOver(),
      }),
    })

    return (
        <div ref={drop} style={{ backgroundColor: isOver ? 'blue' : 'none' }}>
        </div>
      )
}


   
const DragNDrop = () => {
    // Add program to state on drop
    const [items, setItems] = useState(null);
    const handleDrop = useCallback((item) => {
        // Here, 'item' is the object you specified in the 'item' field in your drag source
        // For example, to get the id: item.id
        // You would add your logic here to add the program to your JSON object
        console.log("Dragged Item", item);
        setItems(item);
    }, []);
    return (
        <DndProvider backend={HTML5Backend}>
          <ProgramContainer onDrop={handleDrop} />
          {/* Replace with your actual data */}
          {items}
          {/* More programs... */}
        </DndProvider>
      )
}

  export default DragNDrop;